import React, { FC, ReactNode } from 'react'
import { Block } from '../block'

interface PropositionValeurProps {
  title: string
  children?: ReactNode
}

export const PropositionValeur: FC<PropositionValeurProps> = ({ title, children }) => {
  return (
    <Block title={title} titleSize="4xl" titleMobileSize="3xl" titleAlign="center">
      {children}
    </Block>
  )
}
