import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react'
import cx from 'classnames'

import './input.css'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string,
  name: string,
  label: string,
  placeholder?: string,
  home?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: any,
  disabled?: boolean,
  required?: boolean,
  hideLabel?: boolean
  onChange?: (e: ChangeEvent) => void,
  value?: string,
  error?: string,
  margin?: boolean,
  padding?: string,
  labelGray?: boolean,
  labelDark?: boolean,
}

export const Input: FC<InputProps> = ({
  type,
  name,
  inputRef,
  label,
  disabled = false,
  required,
  hideLabel = false,
  placeholder = '',
  home = false,
  onChange,
  value = undefined,
  error = undefined,
  margin = false,
  labelGray = false,
  labelDark = false,
  ...props
}) => {
  return (
    <div
      className={cx('input-container', {
        'input-container--margin': margin,
        'input-container--date': type === 'date',
        'input-container--home': home,
      })}
    >
      <label
        htmlFor={name}
        className={cx('input__label', {
          'sr-only ': hideLabel,
          'input__label--gray': labelGray,
          'input__label--dark': labelDark,
          'input__label--home': home,
        })}
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        ref={inputRef}
        className={cx('input', {
          'input--error': error,
          'input--date': type === 'date',
          'input--home': home,
        })}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        onChange={e => onChange && onChange(e)}
        value={value}
        {...props}
      />
      {error && (
        <p className="input__error-message">
          {error}
        </p>)}
    </div>
  )
}
