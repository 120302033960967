import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Hero,
  Image,
  ImageContainer,
  LayoutCommunication,
  TypologieProfession,
  Offre,
  PropositionValeur,
  PropositionValeurArticle,
  HorizontalRuleCommunication,
  Users,
  BadgeCheck,
  Swatch,
} from '../../components'
import { OffreCard } from '../../components/communication/offre/offre-card'

import etablissementsSante from '../../assets/images/structures__etablissements-de-sante.jpg'
import professionsLiberales from '../../assets/images/structures__professions-liberales.jpg'
import officines from '../../assets/images/structures__officines.jpg'
import centresSante from '../../assets/images/structures__centres-de-sante.jpg'
import entreprisesPrivees from '../../assets/images/structures__entreprises-privees.jpg'


export const Employeur: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutCommunication>
      <Hero
        title={t('employeur.hero.title')}
        subtitle={t('employeur.hero.subtitle')}
        type="employeur"
        button={t('employeur.hero.button')}
        buttonLink={`mailto:${t('mail_to_contact')}`}
      />
      <PropositionValeur title={t('employeur.propositions.title')}>
        <PropositionValeurArticle text={t('employeur.propositions.valeur.candidat')} icon={<BadgeCheck />} />
        <PropositionValeurArticle text={t('employeur.propositions.valeur.offre')} icon={<Swatch />} />
        <PropositionValeurArticle text={t('employeur.propositions.valeur.gestion')} icon={<Users />} />
      </PropositionValeur>
      <HorizontalRuleCommunication />
      <TypologieProfession title={t('employeur.professions.title')}>
        <ImageContainer>
          <Image
            src={etablissementsSante}
            alt={t('alt_image_profession')}
            caption={t('employeur.professions.etablissementsSante.title')}
          />
          <Image
            src={professionsLiberales}
            alt={t('alt_image_profession')}
            caption={t('employeur.professions.professionsLiberales.title')}
          />
          <Image
            src={officines}
            alt={t('alt_image_profession')}
            caption={t('employeur.professions.officines.title')}
          />
          <Image
            src={centresSante}
            alt={t('alt_image_profession')}
            caption={t('employeur.professions.centresSante.title')}
          />
          <Image
            src={entreprisesPrivees}
            alt={t('alt_image_profession')}
            caption={t('employeur.professions.entreprisesPrivees.title')}
          />
        </ImageContainer>
      </TypologieProfession>
      <Offre
        title={t('employeur.offre.title')}
        buttonLink={`mailto:${t('mail_to_contact')}`}
        buttonText={t('employeur.offre.button')}
      >
        <OffreCard
          title={t('employeur.offre.interim_card_title')}
          text={t('employeur.offre.interim_card_text')}
        />
        <OffreCard
          title={t('employeur.offre.vacation_card_title')}
          text={t('employeur.offre.vacation_card_text')}
          marginTop={true}
        />
        <OffreCard
          title={t('employeur.offre.cdd_cdi_card_title')}
          text={t('employeur.offre.cdd_cdi_card_text')}
        />
      </Offre>
    </LayoutCommunication>
  )
}
