import React, { FC } from 'react'
import cx from 'classnames'


import { isEmpty } from 'lodash'
import styles from './avantages-item.module.css'

export interface AvantagesItemProps {
  label: string,
  avantage?: string,
  last?: {
    first : string,
    end: string,
  },
}

export const AvantagesItem: FC<AvantagesItemProps> = ({ label, avantage = '', last = {} }) => {
  return (
    <div className={cx(styles['avantages-item'], { [styles['avantages-item--margin-bottom']]: isEmpty(last) })}>
      <div className={styles['avantages-item__label']}>
        {label}
      </div>
      { avantage && (
        <div className={styles['avantages-item__avantage']}>
          {avantage}
        </div>
      )}
      { last && (
        <div className={styles['avantages-item__last']}>
          <div className={styles['avantages-item__first']}>{last.first}</div>
          <div className={styles['avantages-item__end']}>{last.end}</div>
        </div>
      )}
    </div>
  )
}
