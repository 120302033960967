import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../../../components/form/checkbox/checkbox'
import { EtablissementTypeEnum } from '../../../graphql/graphql'
import { ProfessionForm } from '../../../pages/register/register.helper'

type EtablissementType =
  'CENTRE_DE_SANTE' |
  'CABINET_MEDICAL' |
  'CENTRE_DE_SOIN_ET_DE_READAPTATION' |
  'CLINIQUE' |
  'EHPAD' |
  'HOPITAL' |
  'AUTRES_ETABLISSEMENTS'

interface CheckboxEtablissementProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: any,
  professionInputs?: ProfessionForm
}

export const CheckboxEtablissement: FC<CheckboxEtablissementProps> = ({ inputRef, professionInputs }) => {
  const { t } = useTranslation()

  const options = [
    { value: EtablissementTypeEnum.HOPITAL, label: t('register.job_criteria.etablissement.hopital') },
    { value: EtablissementTypeEnum.CLINIQUE, label: t('register.job_criteria.etablissement.clinique') },
    { value: EtablissementTypeEnum.EHPAD, label: t('register.job_criteria.etablissement.ehpad') },
    { value: EtablissementTypeEnum.CENTRE_DE_SANTE, label: t('register.job_criteria.etablissement.centre_de_sante') },
    { value: EtablissementTypeEnum.CENTRE_DE_SOIN_ET_DE_READAPTATION,
      label: t('register.job_criteria.etablissement.centre_de_soin_et_de_readaptation') },
    { value: EtablissementTypeEnum.CABINET_MEDICAL, label: t('register.job_criteria.etablissement.cabinet_medical') },
    { value: EtablissementTypeEnum.AUTRES_ETABLISSEMENTS,
      label: t('register.job_criteria.etablissement.autres_etablissements') },
  ]

  return (
    <>
      { options.map((option) => (
        <Checkbox
          key={option.value}
          name={option.value}
          label={option.label}
          inputRef={inputRef}
          defaultChecked={professionInputs ? professionInputs[`${option.value}` as EtablissementType] : false}
          boldLabel
          testId={`checkbox-${option.value}`}
        />
      ))}
    </>
  )
}
