import React, { FC, MouseEvent } from 'react'
import { Button } from '../button'
import { Send } from '../../icon'

import './chat-layout.css'

export interface ChatLayoutProps {
  onSubmit?: (e: MouseEvent) => void,
}

export const ChatLayout: FC<ChatLayoutProps> = ({ onSubmit = null, children }) => {
  return (
    <div className="chat-layout">
      {children}
      <div className="chat-submit-container">
        <Button onClick={(e) => onSubmit && onSubmit(e)} type="submit" testId="chat-layout-submit">
          <Send />
        </Button>
      </div>
    </div>
  )
}
