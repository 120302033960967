import React, { createRef, FC, useEffect, useState } from 'react'
import { Header } from '../../header'
import { paths } from '../../../routing'
import { usePageOffset } from '../../../hooks/use-page-offset'

import styles from './header.module.css'

export interface HeaderCommunicationProps {
  noHero?: boolean,
}

export const HeaderCommunication: FC<HeaderCommunicationProps> = ({ noHero = false, children }) => {
  const [isHeaderSticky, setHeaderSticky] = useState<boolean>(false)
  const headerRef = createRef<HTMLDivElement>()
  const { pageYOffset } = usePageOffset()

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    if (pageYOffset as number > headerRef.current.offsetHeight) {
      setHeaderSticky(true)
    } else {
      setHeaderSticky(false)
    }
  }, [pageYOffset, headerRef])

  return (
    <div className={styles['header-communication']}>
      <div
        ref={headerRef}
        style={{
          height: 'var(--app-bar-height)',
        }}
      >
        <Header
          logoLink={paths.HOME}
          background={noHero ?
            'white'
            :
            isHeaderSticky
              ? 'white'
              : 'transparent'
          }
          position={isHeaderSticky
            ? 'fixed'
            : 'static'}
        >
          {children}
        </Header>
      </div>
    </div>
  )
}
