import React, { FC } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import {
  getLocationDataJobCriteria,
  isOptionType,
  JobCriteriaForm,
} from  '../../../pages/register/register.helper'
import { ChatItem, ChatItemBubble, ChatLayout, Checkbox, SelectInput } from '../../../components'
import { useStepper } from '../../../hooks'
import { SelectCity } from '../../input'

export interface JobCriteriaFormProps {
  onSubmit: (data: JobCriteriaForm) => void,
}

export const Form: FC<JobCriteriaFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { control, register, handleSubmit } = useForm<JobCriteriaForm>()

  const jobCriteria = getLocationDataJobCriteria(location)
  const initStep = Object.keys(jobCriteria || {}).length === 7 ? 4 : 1

  const { step, nextStep, isLastStep, isCurrentState } = useStepper(initStep, 4)

  const onFormSubmit = (data: JobCriteriaForm) => {
    if (isLastStep()) {
      return onSubmit(data)
    }
    nextStep()
  }

  const options = [
    { value: 'now', label: t('register.job_criteria.availability.now') },
    { value: 'three_months', label: t('register.job_criteria.availability.three_months') },
    { value: 'six_months', label: t('register.job_criteria.availability.six_months') },
    { value: 'never', label: t('register.job_criteria.availability.never') },
  ]

  const distanceOptions = [
    { value: '5', label: '5 km' },
    { value: '10', label: '10 km' },
    { value: '20', label: '20 km' },
    { value: '30', label: '30 km' },
    { value: '50', label: '50 km' },
  ]

  return (
    <form>
      <ChatLayout onSubmit={handleSubmit(onFormSubmit)}>
        <ChatItem avatar={true}>
          <ChatItemBubble fade={isCurrentState(1) ? 'ask' : 'none'}>
            {t('register.job_criteria.start')}
          </ChatItemBubble>
          <ChatItemBubble fade={isCurrentState(1) ? 'ask' : 'none'}>
            {t('register.job_criteria.city.label')}
          </ChatItemBubble>
        </ChatItem>
        <ChatItem position="right">
          <ChatItemBubble fade={isCurrentState(1) ? 'answer' : 'none'}>
            <Controller
              name="city"
              control={control}
              rules={{
                required: true,
                validate: value => isOptionType(value),
              }}
              defaultValue={jobCriteria?.city ? jobCriteria?.city : null}
              render={props => (
                <SelectCity
                  name={props.name}
                  value={props.value}
                  onChange={props.onChange}
                />
              )}
            />
          </ChatItemBubble>
        </ChatItem>
        {step >= 2 && (
          <>
            <ChatItem avatar={true}>
              <ChatItemBubble fade={isCurrentState(2) ? 'ask' : 'none'}>
                {t('register.job_criteria.distance.label')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={isCurrentState(2) ? 'answer' : 'none'} textPadding>
                <Controller
                  name="distance"
                  control={control}
                  rules={{
                    required: true,
                    validate: value => isOptionType(value),
                  }}
                  defaultValue={jobCriteria?.distance ? jobCriteria?.distance : distanceOptions[0]}
                  render={props => (
                    <SelectInput
                      label="distance"
                      hideLabel={true}
                      placeholder=""
                      search={false}
                      name={props.name}
                      value={props.value}
                      onChange={props.onChange}
                      options={distanceOptions}
                      textAlign="left"
                      width="30rem"
                    />
                  )}
                />
              </ChatItemBubble>
            </ChatItem>
          </>)}
        {step >= 3 && (
          <>
            <ChatItem avatar={true}>
              <ChatItemBubble fade={isCurrentState(2) ? 'ask' : 'none'}>
                {t('register.job_criteria.contract_type.label')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={isCurrentState(2) ? 'answer' : 'none'} textPadding>
                <p>
                  {t('register.job_criteria.contract_type.answer')}
                </p>
                <Checkbox
                  name='CDI'
                  label={t('contract_type.CDI')}
                  inputRef={register}
                  defaultChecked={jobCriteria?.CDI}
                  boldLabel
                  testId="checkbox-cdi"
                />
                <Checkbox
                  name='CDD'
                  label={t('contract_type.CDD')}
                  inputRef={register}
                  defaultChecked={jobCriteria?.CDD}
                  boldLabel
                />
                <Checkbox
                  name='INTERIM'
                  label={t('contract_type.INTERIM')}
                  inputRef={register}
                  defaultChecked={jobCriteria?.INTERIM}
                  boldLabel
                />
                <Checkbox
                  name='VACATION'
                  label={t('contract_type.VACATION')}
                  inputRef={register}
                  defaultChecked={jobCriteria?.VACATION}
                  boldLabel
                />
                <Checkbox
                  name='AUTO_ENTREPRENEUR'
                  label={t('contract_type.AUTO_ENTREPRENEUR')}
                  inputRef={register}
                  defaultChecked={jobCriteria?.AUTO_ENTREPRENEUR}
                  boldLabel
                />
              </ChatItemBubble>
            </ChatItem>
          </>)}
        {step >= 4 && (
          <>
            <ChatItem avatar={true}>
              <ChatItemBubble fade={isCurrentState(3) ? 'ask' : 'none'}>
                {t('register.job_criteria.availability.label')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={isCurrentState(3) ? 'answer' : 'none'}>
                <Controller
                  name="availability"
                  control={control}
                  rules={{
                    required: true,
                    validate: value => isOptionType(value),
                  }}
                  defaultValue={jobCriteria?.availability ? jobCriteria?.availability : options[0]}
                  render={props => (
                    <SelectInput
                      label="availability"
                      hideLabel={true}
                      placeholder=""
                      search={false}
                      name={props.name}
                      value={props.value}
                      onChange={props.onChange}
                      options={options}
                      textAlign="left"
                      width="30rem"
                    />
                  )}
                />
              </ChatItemBubble>
            </ChatItem>
          </>)}
        {step >= 5 && (
          <>
            <ChatItem avatar={true}>
              <ChatItemBubble fade={isCurrentState(4) ? 'ask' : 'none'}>
                {t('register.job_criteria.end')}
              </ChatItemBubble>
            </ChatItem>
          </>)}
      </ChatLayout>
    </form>
  )
}
