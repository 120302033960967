import React, { FC } from 'react'
import { Circle } from '../'
import { Card } from '../..'
import styles from './stepper-item.module.css' 

export interface StepperItemProps {
  step: number,
  text: string,
}

export const StepperItem:FC<StepperItemProps> = ({ step = 1, text }) => {
  return (
    <li className={styles['stepper-item']}>
      <div className={styles['stepper-item__step']}>
        <Circle step={step} />
      </div>
      <Card
        className={styles['stepper-item__card']}
      >
        {text}
      </Card>
    </li>
  )
}
