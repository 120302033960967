/* eslint-disable max-len */

export const translations =
{
  fr: {
    translations: {
      head: {
        meta: {
          description: 'Ipokrat, spécialiste en recrutement des métiers du secteur de la santé',
        },
      },
      benefices_recherches: {
        title: 'Pourquoi faire partie de la team Ipokrat?',
        valorisation: {
          title: 'Nous valorisons vos compétences et votre expérience',
          text: 'Votre carrière professionnelle et vos diplômes font qui vous êtes; découvrez qui vous serez avec IPOKRAT.',
        },
        recompense: {
          title: 'Nous récompensons votre talent et votre fidélité',
          text: 'Soyez inspiré.e, soyez inspirant.e. Entrez dans une relation gagnant/gagnant avec IPOKRAT. ',
          link: 'Découvrez vos avantages ',
        },
        choix: {
          title: 'Nous vous donnons le choix de travailler comme vous voulez',
          text: 'De temps en temps ou en continu; c’est vous qui décidez de tout. Reprenez le contrôle de votre vie avec IPOKRAT.',
        },
      },
      error: {
        ERROR: 'Erreur',
        LOGIN_ERROR: 'Email ou mot de passe incorrect',
        REGISTER_ERROR: 'Un compte existe déjà pour cet utilisateur',
        FORMATION_NOT_FOUND: 'Cette formation n\'existe pas',
        EXPERIENCE_NOT_FOUND: 'Cette expérience n\'existe pas',
        REGISTER_INVALID_PASSWORD: 'Mot de passe invalide',
        REGISTER_INVALID_PHONE: 'Numéro de téléphone invalide',
        INVALID_TOKEN: 'Lien de réinitialisation invalide',
      },
      home: {
        hero: {
          title: 'Le job de vos rêves n\'attend plus que vous',
          subtitle: 'Interim. Vacation. CDD. CDI.\nIpokrat, votre partenaire 100% digital pour trouver un emploi dans la santé',
        },
        form: {
          send: 'C\'est parti',
        },
        process_step: {
          title: 'Prêt à travailler avec Ipokrat?',
          step1: {
            text: 'Inscrivez vous en quelques clicks et créez votre profil',
          },
          step2: {
            text: 'On se rencontre (visio/tel) pour faire connaissance et valider votre candidature',
          },
          step3: {
            text: 'On s’occupe de vous trouver le job de vos rêves',
          },
        },
      },
      not_found: {
        content: 'OUPS... Nous n\'avons pas trouvé cette page',
        return_home: 'Retourner à l\'accueil',
      },
      qui_sommes_nous: {
        title: 'Qui sommes-nous ?',
        proposition_valeur: {
          right: 'Ipokrat, c’est l’histoire de deux regards croisés il y a plus de 20 ans ; ' +
            'un couple qui s’est associé dans la vie comme en affaires. Deux entrepreneurs qui se sont lancés dans une aventure passionnante après avoir revendu leur dernière société au printemps 2020.',
          middle: 'Le projet résonnait déjà depuis longtemps. Il résultait du constat de leurs expériences dans le domaine du recrutement et de la santé : comment répondre aux problématiques RH des métiers médicaux et paramédicaux telles que l’accompagnement de carrières, et la mise à disposition de talents sur-mesure, avec la réactivité nécessaire ?',
          left: 'Quand les deux quadragénaires inséparables de leur smartphone ont pensé leur offre, ' +
            'ils ne l’imaginaient pas autrement que digitale et adaptée aux contraintes budgétaires des établissements de santé.\n' +
            'En 2020, la crise sanitaire a fini de les convaincre pour créer leur application.',
        },
        hero: {
          title: 'Croiser les regards et les expériences',
        },
        chiffres: {
          item1: {
            title: 'profils complémentaires',
          },
          item2 : {
            title: 'années d’expérience respective',
            text: 'Dans l’intérim, puis la distribution et promotion dans le domaine médical. Des expériences entrepreneuriales de terrain, des rencontres multiples et une expertise professionnelle qui ont inspiré le couple dans sa recherche de solutions.',
          },
          item3 : {
            title: 'métiers recensés et concernés par l’offre Ipokrat',
            text: 'Presqu’autant de profils rencontrés et interviewés/interrogés pour concevoir un outil sur-mesure, une méthode adaptée à vos besoins et vos pratiques.',
          },
          item4 : {
            title: 'marque le démarrage de l\'activité de recrutement pour Christelle & Yannis',
          },
          item5 : {
            title: 'Naissance d\'IPOKRAT',
          },
        },
      },
      footer: {
        telephone: '+33481682690',
        telephone_text: 'Tel:  04 81 68 26 90',
      },
      vos_avantages: {
        title: 'Vos avantages',
        process_step: {
          title: 'Boostez votre pouvoir d’achat avec IPOKRAT',
          step1: 'De 30€ à 100€ lors de votre 1er job grâce à votre talent',
          step2: 'En augmentant votre salaire grâce à votre fidélité',
          step3: 'De 50€ à 150€ en parrainant votre entourage',
        },
        avantages: {
          link: 'Je crée mon compte !',
          sub_label1: 'Versements effectués en début de mois pour le mois précédent sur votre carte cadeau.',
          sub_label2: '*Le montant dépend de la catégorie professionnelle de votre filleul.e. Nombre de filleul.e illimité. Versement au premier job de votre filleul.e. Valable pour tous contrats de travail.',
          sub_label3: '**Valable pour les contrats d\'intérim. Ex: vous percevez 1950€ de salaire net, nous créditons de 19,50€ votre carte cadeaux.',
          item1: {
            title: 'Team #1',
            text: 'aide-soignant.e, éducateur.rice spécialisé.e, aide médico-psychologique, auxiliaire de vie, agent de stérilisation, technicien.ne en laboratoire',
            first: {
              label: 'Votre talent pour votre 1er job',
              avantage: '30€',
            },
            second: {
              label: 'Vos recommandations* pour chaque filleul.e',
              avantage: '50€',
            },
            third: {
              label: 'Votre fidélité** pour chaque job',
              last: {
                first: '1% de plus',
                end: 'de votre salaire net',
              },
            },
          },
          item2: {
            title: 'Team #2',
            text: 'infirmier.e, infirmier.e spécialisé.e, infirmier.e cadre ou puériculteur.rice',
            first: {
              label: 'Votre talent pour votre 1er job',
              avantage: '50€',
            },
            second: {
              label: 'Vos recommandations* pour chaque filleul.e',
              avantage: '80€',
            },
            third: {
              label: 'Votre fidélité** pour chaque job',
              last: {
                first: '1% de plus',
                end: 'de votre salaire net',
              },
            },
          },
          item3: {
            title: 'Team #3',
            text: 'médecin, pharmacien, dentiste, sage-femme, kiné, ergo',
            first: {
              label: 'Votre talent pour votre 1er job',
              avantage: '100€',
            },
            second: {
              label: 'Vos recommandations* pour chaque filleul.e',
              avantage: '150€',
            },
            third: {
              label: 'Votre fidélité** pour chaque job',
              last: {
                first: '1% de plus',
                end: 'de votre salaire net',
              },
            },
          },
        },
      },
      employeur: {
        title: 'Employeur',
        offre: {
          title: 'Notre offre',
          vacation_card_title: 'Vacation',
          vacation_card_text: 'Une commission fixe en fonction du talent et de la durée du contrat.',
          interim_card_title: 'Interim',
          interim_card_text: 'Des prix de facturation très avantageux, et dégressifs.',
          cdd_cdi_card_title: 'CDI / CDD',
          cdd_cdi_card_text: 'Une commission fixe calculée en fonction du salaire annuel du talent recruté',
          button: 'Demandez un devis !',
        },
        hero: {
          title: 'Recruter avec Ipokrat',
          subtitle: 'Intérim, Vacation, CDD, CDI :\nnous avons la solution pour vous faciliter vos recrutements',
          button: 'Contactez-nous !',
        },
        propositions: {
          title: 'Nos engagements',
          valeur: {
            candidat: 'Des candidat.e.s disponibles et validé.e.s par nos consultant.e.s issu.e.s de la santé.',
            offre: 'Une offre sur mesure adaptée à votre structure et un coût tarifaire avantageux.',
            gestion: 'Une gestion administrative allégée et un.e interlocuteur.rice dédié.e au quotidien.',
          },
        },
        professions: {
          title: 'Quelle que soit votre structure',
          etablissementsSante: {
            title: 'Établissements de santé',
          },
          professionsLiberales: {
            title: 'Professions libérales',
          },
          officines: {
            title: 'Officines',
          },
          centresSante: {
            title: 'Centres de santé',
          },
          entreprisesPrivees: {
            title: 'Entreprises privées',
          },
        },
      },
      contract_type: {
        CDI: 'CDI',
        CDD: 'CDD',
        INTERIM: 'Interim',
        VACATION: 'Vacation',
        AUTO_ENTREPRENEUR: 'Auto entrepreneur',
      },
      service_type: {
        URGENCES: 'Urgences',
        ONCONLOGIE: 'Oncologie',
        SOINS_INTENSIFS: 'Soins intensifs',
        SOINS_GENERAUX: 'Soins généraux',
        SERVICE_GERIATRIE: 'Gériatrie',
        PSYCHIATRIE: 'Psychiatrie',
        NEUROLOGIE: 'Neurologie',
        CHIRURGIE: 'Chirurgie',
        MEDECINE_INTERNE: 'Médecine interne',
        MATERNITE: 'Maternité',
        ANESTHESIE: 'Anesthesie',
        CARDIOLOGIE: 'Cardiologie',
        REANIMATION: 'Réanimation',
        UROLOGIE: 'Urologie',
        PEDIATRIE: 'Pédiatrie',
        AUTRE: 'Autre service',
      },
      domaine_type: {
        SOINS: 'Soins',
        SOINS_A_DOMICILE: 'Soins à domicile',
        PETITE_ENFANCE: 'Petite enfance',
        GERIATRIE: 'Gériatrie',
        AUTRE_DOMAINE: 'Autre domaine',
      },
      poste_type: {
        CHEF_DE_CLINIQUE: 'Chef de clinique',
        CHEF_DE_SERVICE: 'Chef de service',
        INTERNE: 'Interne',
        LIBERAL: 'Libéral',
        PRATICIEN_HOSPITALIER: 'Praticien hospitalier',
        PEU_IMPORTE: 'Peu m\'importe',
      },
      etablissement_type: {
        CENTRE_DE_SANTE: 'Centre de santé',
        CABINET_MEDICAL: 'Cabinet médical',
        CENTRE_DE_SOIN_ET_DE_READAPTATION: 'Centre de soin et de réadaptation',
        CLINIQUE: 'Clinique',
        EHPAD: 'Ehpad',
        HOPITAL: 'Hôpital',
        AUTRES_ETABLISSEMENTS: 'Autres établissements',
      },
      'home_page': 'Page d\'accueil',
      'form_label_email': 'Email *',
      'form_label_password': 'Mot de passe *',
      'form_button_create_account': 'S\'inscrire',
      'ipokrat_engagement': 'La promesse d\'Ipokrat',
      'who_we_are': 'Qui sommes-nous ?',
      'ressources': 'Ressources',
      'others': 'Autres',
      'recruit_talent': 'Recruter un talent',
      'recrut_offer': 'Offre employeur',
      'contact_us': 'Contactez-nous',
      'connect': 'Se connecter',
      'registered': 'Trouver un job',
      'disconnect': 'Se déconnecter',
      'confirm_account': 'Votre compte est validé',
      'confirm_account_loading': 'Validation',
      'proposition_valeur_title': 'Votre emploi dans la santé rapide, simple et convivial',
      'proposition_valeur_text_rapide': 'Vous nous dites qui vous êtes et ce que vous voulez',
      'proposition_valeur_text_simple': 'Notre solution de matching vous propose des emplois correspondants',
      'proposition_valeur_text_convivial': 'Un.e conseiller.ère vous accompagne dans votre recherche et vos missions',
      'lorem': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempor non dolor in scelerisque. Ut dignissim facilisis mollis. Vestibulum ante ipsum primis in faucibus orci.',
      'typologie_profession_title': 'Quelle que soit votre profession',
      'alt_image_profession': 'Image de profession',
      'caption_profession_paramedicale': 'Professions paramédicales',
      'caption_profession_medicale': 'Professions médicales',
      'caption_profession_administrative': 'Professions administratives et techniques',
      'caption_profession_pharma': 'Professions de l\'industrie pharmaceutique',
      'fondateur_title': 'Nous connaissons vos métiers',
      'fondateur_text': 'Nous avons imaginé Ipokrat grace à vous, grace à nos 20 années passées dans les secteurs de la santé et du recrutement',
      'fondateur_link': 'Qui sommes-nous ?',
      'engagement_title': 'Mettez toutes les chances de votre côté',
      'engagement_text': 'Envie d\'aller plus loin ? Envie d\'intégrer la team Ipokrat? Rapide, sans engagement. Rejoignez nous',
      'find_job': 'Trouver un job',
      'recruitment_offer': 'Notre offre recruteur',
      'ipokrat_copyright': '© Copyright {{date, yyyy}} Ipokrat. Tous droits réservés.',
      'about': 'À propos',
      'CGU_CGV': 'CGU / CGV',
      'CGU_candidat': 'CGU candidats',
      'CGU_employeur': 'CGU employeurs',
      'mentions_legales': 'Mentions légales',
      'CGV': 'CGV',
      'legal_notice': 'Mentions légales',
      'preferences_cookies': 'Préférences cookies',
      'protection_data': 'Protections des données',
      'mail_to_contact': 'hello@ipokrat.fr',
      'register_first_meet': 'Bonjour 👋, \nJe suis Christelle, la cofondatrice d\'Ipokrat. Je vais vous accompagner dans la saisie de votre inscription.',
      'register_ask_firstname': 'Quel est votre prénom ?',
      'register_ask_lastname': 'Et votre nom ?',
      'register_ask_phone': 'Nous avons aussi besoin de votre numéro de téléphone afin de vous contacter au sujet de votre recherche.',
      'next': 'Continuer',
      'prev': 'Retour',
      'choose_job': 'Choisir un métier',
      'what_job_do_you_seek': 'Pour commencer, quel métier recherchez-vous ?',
      'save': 'Sauvegarder',
      'since_date': 'Depuis {{date, format}}',
      login:{
        connect: 'Connexion',
        button: 'Je me connecte',
        forgotten_password: 'Mot de passe oublié ?',
      },
      forgotten_password: {
        title: 'Mot de passe oublié ?',
        text: 'Pas de panique ! entrez votre e-mail pour recevoir un lien afin de réinitialiser votre mot de passe :',
        button: 'Réinitialiser mon mot de passe',
        success: {
          title: 'Un e-mail a été envoyé à l’adresse {{email}}',
          text: 'Vérifiez vos e-mail et cliquez sur le lien qui vous permettra de réinitialiser votre mot de passe !',
          verify: 'Vous n’avez pas reçu d’email ? Verifier les indésirables.',
        },
      },
      reset_password: {
        title: 'Réinitialiser votre mot de passe',
        success: {
          title: 'Mot de passe modifié avec succès !',
          link: 'Se connecter',
        },
      },
      register: {
        job_criteria: {
          start: 'Afin de préciser les propositions que nous vous ferons, précisez vos critères de recherche d’emploi.',
          availability: {
            label: 'Merci ! Dernière question, quand êtes-vous disponible ?',
            now: 'De suite',
            three_months: 'Sous 3 mois',
            six_months: 'Sous 6 mois',
            never: 'Pas dispo',
          },
          domaine: {
            question: 'Dans quel domaine souhaitez-vous exercer ?',
            label: 'Domaines :',
            soins: 'Soins',
            geriatrie: 'Gériatrie',
            soins_domicile: 'Soins à domicile',
            petite_enfance: 'Petite enfance',
            autre_domaine: 'Autre domaine',
          },
          poste: {
            question: 'Concernant le poste, avez-vous des préférences d\'exercice ?',
            label: 'Je souhaite exercer en :',
            chef_clinique: 'Chef de clinique',
            chef_service: 'Chef de service',
            interne: 'Interne',
            liberal: 'Libéral',
            praticien_hospitalier: 'Praticien hospitalier',
            peu_importe: 'Peu m\'importe',
          },
          etablissement: {
            question: 'Pour finir, quels établissements feront votre bonheur ?',
            label: 'Les établissements suivants sont dans mon collimateur :',
            centre_de_sante: 'Centre de santé',
            cabinet_medical: 'Cabinet médical',
            centre_de_soin_et_de_readaptation: 'Centre de soin et de réadaptation',
            clinique: 'Clinique',
            ehpad: 'Ehpad',
            hopital: 'Hôpital',
            autres_etablissements: 'Autres établissements',
          },
          contract_type: {
            label: 'Quel type de contrat préférez vous ?',
            answer: ' Je souhaite exercer en :',
          },
          city: {
            label: 'Dans quelle ville habitez vous ?',
          },
          distance: {
            label: 'Quelle distance acceptez vous de faire pour vous rendre au travail (en Kms) ?',
          },
          service_tag: {
            label: 'Dans quels services préférez vous exercer ?',
            placeholder: 'Saisissez un service',
            urgences: 'Urgences',
            onconlogie: 'Oncologie',
            soins_intensifs: 'Soins intensifs',
            soins_generaux: 'Soins généraux',
            geriatrie: 'Geriatrie',
            psychiatrie: 'Psychiatrie',
            neurologie: 'Neurologie',
            chirurgie: 'Chirurgie',
            medecine_interne: 'Médecine interne',
            maternite: 'Maternité',
            anesthesie: 'Anesthesie',
            cardiologie: 'Cardiologie',
            reanimation: 'Réanimation',
            urologie: 'Urologie',
            pediatrie: 'Pédiatrie',
            autre: 'Autre',
          },
          end: 'Merci ! Nous les rajouterons sur votre profil 😉',
        },
      },
      professions: {
        medecin: 'Médecin',
        allergologue: 'L\'allergologie ou l\'immunologie',
        anesthesiste_reanimateur: 'Anesthésiologie',
        chirurgien_plasticien: 'La chirurgie plastique et reconstructive',
        chirurgien_maxillo_facial: 'La chirurgie maxilo-faciale',
        chirurgien_pediatrique: 'La chirurgie pédiatrique',
        chirurgie_vasculaire: 'La chirurgie vasculaire',
        neurochirurgien: 'La neurochirurgie',
        dermatologue: 'La dermatologie',
        endocrinologue: 'L\'endocrinologie',
        gastro_enterologue: 'La gastro-entérologie',
        geriatrie: 'La gériatrie',
        gynecologue: 'La gynécologie',
        hematologue: 'L\'hématologie',
        proctologue: 'La proctologie',
        pedopsychiatre: 'La pédopsychiatrie',
        medecin_travail: 'La médecine du travail',
        medecin_nucleaire: 'La médecine nucléaire',
        medecin_reeducateur: 'La médecine physique et réadaptation',
        medecin_scolaire: 'La médecine scolaire',
        medecin_tropical: 'La médecine tropicale',
        medecin_sport: 'La médecine du sport',
        medecin_generaliste: 'Médecin généraliste',
        nephrologue: 'La néphrologie',
        neurologue: 'La neurologie',
        oncologue: 'L\'oncologie',
        ophtalmologue: 'L\'ophtalmologie',
        chirurgien_orthopedique: 'L\'orthopédie',
        orl: 'L\'oto-rhino-laryngologie',
        pediatre: 'La pédiatrie',
        pneumologue: 'La pneumologie',
        psychiatre: 'La psychiatrie',
        radiologue: 'La radiologie',
        radiotherapeute: 'La radiothérapie',
        rhumatologue: 'La rhumatologie',
        urologue: 'L\'urologie',
        nutritioniste: 'La nutrition',
        medecin_biologiste: 'Médecin biologiste',
        anatomopathologiste: 'Anatomopathologiste',
        addictologue: 'Addictologue',
        medecin_interniste: 'Interniste',
        medecin_urgentiste: 'Médecin Urgentiste',
        chirurgien_digestif: 'Chirurgien digestif',
        medecin_homeopathe: 'Homéopathie',
        infirmier: 'Infirmier',
        dentiste: 'Dentiste',
        medecine_buccale: 'Médecine buccale',
        anesthesiologie: 'Anesthésiologie',
        interne: 'Interne',
        naturopthie: 'Naturopthie',
        cardiologie: 'Cardiologie',
        chirurgie_generale: 'Chirurgie Générale',
        orthodontie: 'Orthodontie',
        endodontie: 'Endodontie',
        chirurgien_dentiste: 'Chirurgien dentitste',
        stomatologue: 'stomatologie',
        chirurgie_buccale: 'La chirurgie buccale et maxilo-faciale',
        pharmacien_officine: 'Pharmacien en officine',
        pharmacien_hospitalier: 'Pharmacien hospitalier',
        pharmacien_industrie: 'Pharmacien industriel',
        infirmier_anesthesiste: 'Infirmier anesthésiste',
        infirmier_bloc_operatoire: 'Infirmier de bloc opératoire',
        infirmier_cadre: 'Infirmier Cadre',
        infirmier_liberal: 'Infirmier Libéral',
        infirmier_pratique_avancee: 'Infirmier en pratique avancée',
        infirmier_domicile: 'Infirmier à domicile',
        infirmier_coordinateur: 'Infirmier Coordinateur',
        infirmier_puericulteur: 'Puériculteur',
        aide_soignant: 'Aide-soignant',
        instrumentiste: 'instrumentiste',
        assistant_medical: 'Assistant Médical',
        osteopathe: 'Ostéopathe',
        chiropracteur: 'Chiropracteur',
        psychologue_clinicien: 'Psychologue CLinicien',
        cadre_sante: 'Cadre de santé',
        psychotherapeute: 'Pschothérapeute',
        psychologue: 'Psychologue',
        veterinaire: 'Vétérinaire',
        masseur_kinesitherapeute: 'Masseur Kinésithérapeute',
        orthophoniste: 'Orthophoniste',
        pedicure_podologue: 'Pédicure - Podologue',
        ergotherapeute: 'Ergothérapeute',
        psychomotricien: 'Psychomotricien',
        dieteticien: 'Diététicien',
        orthoptiste: 'Orthoptiste',
        podo_orthesiste: 'Podo-orthésiste',
        prothesiste_orthesiste: 'Orthoprothésiste',
        technicien_orthopediste: 'Orthopédiste',
        prothesiste_dentaire: 'Prothésiste Dentaire',
        audio_prothesiste: 'Audioprothésiste',
        opticien: 'Opticien Lunetier',
        assistant_technique_installateur_materiel_medical: 'Assistant technique installateur de matériel médical',
        aide_dentaire: 'Aide dentaire',
        technicien_respiratoire: 'Technicien d\'intervention sur matériels d\'assistance respiratoire',
        manipulateur_radiologie: 'Manipulateur en radiologie',
        preparateur_pharmacie_hospitaliere: 'Préparateur en pharmacie hospitalière',
        preparateur_pharmacie: 'Préparateur en pharmacie',
        agent_service_hospitalier: 'Agent de service hospitalier',
        ambulancier: 'Ambulancier',
        brancardier: 'Brancardier',
        aide_operatoire: 'Aide Opératoire',
        agent_sterilisation: 'Agent de stérilisation',
        assistant_collecte_sang: 'Assistant collecte de sang',
        auxiliaire_puericulture: 'Auxiliaire puériculture',
        aide_domicile: 'Aide à domicile',
        assistant_dentaire: 'Assistant dentaire',
        educateur_jeunes_enfants: 'Educateur de jeunes enfants',
        assistant_maternel: 'Assistant maternel',
        auxiliaire_vie: 'Auxiliaire de vie',
        visiteur_medical: 'Visiteur médical',
        sage_femme: 'Sage-Femme',
        pharmacien: 'Pharmacien',
        directeur_etablissement_hospitalier: 'Directeur d\'hôpital',
        secretaire_medical: 'Secrétaire médicale',
        ingenieur_biomedical: 'Ingénieur biomédical',
        directeur_adjoint_etablissement_hospitalier: 'Directeur Adjoint Établissement Hospitalier',
        directeur_centre_reeducation: 'Directeur de Centre de Rééducation',
        directeur_maison_retraite: 'Directeur de Maison de Retraite',
        directeur_creche: 'Directeur de crèches',
        technicien_laboratoire_medical: 'Technicien de Laboratoire Médical',
        educateur_specialise: 'Éducateur spécialisé',
        moniteur_educateur: 'Moniteur éducateur',
        veilleur_nuit: 'Veilleur de nuit',
        aide_medico_psychologique: 'Aide médico psychologique',
        accompagnant_educatif_social: 'Accompagnant éducatif et social',
        technicien_superieur_economie_sociale_familiale: 'Technicien supérieur Économie sociale familiale',
        conseiller_economie_sociale_familiale: 'Conseiller en économie sociale familiale',
        technicien_intervention_sociale_familiale: 'Technicien de l\'intervention sociale et familiale',
        surveillant_nuit: 'Surveillant de nuit',
        eleve_educateur_specialise: 'Élève éducateur spécialisé',
        aide_educateur: 'Aide éducateur',
      },
      profile: {
        settings: {
          delete_account: 'Supprimer le compte',
          delete: {
            button: 'Supprimer mon compte',
            title: 'Supprimer le compte',
            text: 'En supprimant votre compte, vous perdrez votre profil et vos informations personnelles définitivement.',
            success: 'Votre compte à bien été supprimé',
          },
          information: {
            title: 'Mes informations',
            submit: 'Enregistrer les modifications',
            required: 'Tous les champs sont obligatoires.',
            modified: 'Informations mises à jour avec succès',
          },
          modale_title: 'Supprimer votre compte',
        },
        search: {
          title: 'Mes recherches',
          update: 'Modifier',
          update_success: 'Votre recherche à été mis à jour',
          speciality: 'Spécialité',
          service: 'Service',
          domaine: 'Domaine',
          poste: 'Poste',
          etablissement: 'Établissement',
          typology: 'Typologie',
          location: 'Lieux',
          available: 'Disponible de suite',
          available_in: 'Disponible {{ date, distance }}',
          not_available: 'Pas disponible',
        },
        upload: {
          title: 'Envoi de documents',
          subtitle: 'Ajoutez vos diplômes, CV ou tout autre document pour compléter votre profil :',
          button: 'Envoyer un document',
        },
        placeholder: {
          formation: {
            description: 'Dans nos domaines d’activité les diplômes sont important. Partagez-les !',
            button_text: 'Ajouter une formation',
          },
          experience: {
            description: 'Vos expériences font votre force. Détaillez-les pour les partager à de futurs recruteur sur Ipokrat.',
            button_text: 'Ajouter une expérience',
          },
        },
        todos: {
          phone: 'ajouter un numéro de téléphone (depuis vos préférences)',
          formation: 'ajouter une première formation',
          experience: 'ajouter une première expérience',
        },
        armado: {
          account: 'Votre compte',
          text: 'Connectez-vous à votre espace Armado pour avoir accès à vos contrats, documents et saisie des heures :',
          button: 'Se connecter à Armado',
        },
      },
      formation: {
        'formations': 'Formations',
        'add_formation': 'Ajouter une formation',
        'add_formation_description': 'Ajout d\'une formation à votre profil public',
        'edit_formation': 'Modifier une formation',
        'edit_formation_description': 'Modifier cette formation sur votre profil public',
        'school': 'École',
        'diploma': 'Diplôme',
        'description': 'Description',
        'date_start': 'Date de début',
        'date_end': 'Date d\'obtention (facultatif)',
        'delete': 'Supprimer la formation',
        'success': 'Formations mises à jour',
      },
      experience: {
        'experiences': 'Expériences',
        'add_experience': 'Ajouter une expérience',
        'add_experience_description': 'Ajout d\'une expérience à votre profil public',
        'edit_experience': 'Modifier une expérience',
        'edit_experience_description': 'Modifier cette expérience sur votre profil public',
        'job_title': 'Intitulé de la fonction',
        'employer_name': 'Nom de l\'établissement',
        'is_actual_job': 'J\'occupe actuellement ce poste',
        'description': 'Description',
        'date_start': 'Date d\'entrée',
        'date_end': 'Date de sortie (facultatif)',
        'delete': 'Supprimer l\'expérience',
        'since_date': 'Depuis le {{date, dd/MM/yyyy}}',
        'from_date_to_date': 'Du {{from, dd/MM/yyyy}} au {{to, dd/MM/yyyy}}',
        'success': 'Expériences mises à jour',
      },
      header: {
        private: {
          profile: 'Mon profil',
          preferences: 'Préférences',
        },
      },
      'confirm_profession': 'Très bien, {{profession}} donc ! Les possibilités sont nombreuses, je vous propose donc de préciser notre champ de recherche.',
      'ask_speciality': 'Avez vous des spécialités en tête ?',
      'seek_job': 'Métier recherché',
      'profession_home': 'Et quelle est votre profession ?',
      'seek_job_home': 'Ex: Médecin, Infirmier...',
      'i_am_specialized': 'Oui, je suis spécialisé',
      'i_am_not_specialized': 'Non, je ne suis pas spécialisé',
      'are_you_specialized': 'Êtes-vous spécialisé ?',
      'choose_specialities': 'Choix de spécialités',
      'speciality_not_found': 'Je n ai pas trouvé la spécialité que je recherche',
      credentials: {
        title: 'C\'est parti !',
        links: {
          login: 'Vous avez déjà un compte ? Se connecter',
        },
      },
      registration: {
        finished: 'Rendez vous dans votre boîte email pour finaliser votre inscription sur notre application Troops pour professionnel.le.s de santé, et accéder à tous nos services (offres de job, planning, disponibilité, signature de vos contrats...)',
      },
      cancel: 'Annuler',
      form: {
        error: {
          required: 'champ requis',
        },
        email: {
          label: 'Email',
          error: 'cette email n\'est pas correct',
          placeholder: 'Votre adresse e-mail',
        },
        password: {
          label: 'Mot de passe',
          error: 'votre mot de passe doit contenir au moins 8 caractères avec une majuscule, une minuscule et un chiffre',
          placeholder: 'Votre mot de passe',
          reveal: 'Afficher le mot de passe',
        },
        cgu: {
          label: {
            start: 'En m’inscrivant, j’approuve les ',
            end: ' d’Ipokrat *',
          },
          link: 'conditions générales d’utilisation et la politique de confidentialité',
        },
        firstname: {
          label: 'Prénom',
          placeholder: 'Ex: Nathalie',
        },
        lastname: {
          label: 'Nom',
        },
        select: {
          no_options: 'Aucune option',
        },
        civility: {
          label: 'Civilité',
          options: {
            mr: 'Monsieur',
            mme: 'Madame',
          },
        },
        tel: {
          label: 'Téléphone',
          pattern: 'format invalide',
        },
        birthDate: {
          label: 'Date de naissance',
        },
        upload: {
          label: 'Téléversez un fichier ',
          label_bis: 'cliquez ici.',
          description: 'DOC, PDF jusqu\'à {{ limiteSize }} MB.',
          error: {
            size: 'Votre fichier est trop volumineux, la valeur maximal est fixé à {{ limiteSize }} MB',
            type: 'Ce type de fichier n\'est pas pris en compte',
            default: 'Une erreur est survenue',
            empty: 'Aucun fichier renseigné',
          },
          success: 'Merci, votre fichier a été envoyé avec succès',
        },
      },
    },
  },
  en: {
    translations: {
      'hero_title': 'The oath to find you a new job',
      'hero_subtitle': 'Ipokrat commits to find you jobs of your dreams in health field',
    },
  },
}

export default translations
