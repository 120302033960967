import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Block } from '../block'
import { Arrow } from '../../icon'

import './fondateurs.css'

interface FondateursProps {
  title: string
  text: string
  link:{
    text: string,
    href: string,
  }
}

export const Fondateurs: FC<FondateursProps> = ({ title, text, link }) => {
  return (
    <div className="fondateurs__background">
      <Block>
        <article className="fondateurs__article">
          <div className="fondateurs__article-bloc-title">
            <h3 className="fondateurs__article-title">
              {title}
            </h3>
            <p className="fondateurs__article-title-text">
              {text}
            </p>
            <Link
              to={link.href}
              className="fondateurs__article-bloc-title-link"
            >
              <span>{link.text}</span> <Arrow />
            </Link>
          </div>
          <div className="fondateurs__article-bloc-text">
            <p className="fondateurs__article-bloc-text-paragraph">
              {text}
            </p>
            <Link
              to={link.href}
              className="fondateurs__article-bloc-text-link"
            >
              {link.text}
            </Link>
          </div>
        </article>
      </Block>
    </div>
  )
}
