import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { init, sendPageview } from '../helpers/analitics.helper'

export const useTracking = () => {
  const { pathname } = useLocation()
 
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    sendPageview(pathname)
  }, [pathname])
}
