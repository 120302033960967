import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IpokratLogo } from '../logo'
import { cguCandidatUrl, cguEmployeurUrl, mentionsLegalesUrl } from '../../containers/cgu'
import { paths } from '../../routing'
import { FooterLinkBlock } from './link-block'
import { FooterSocial } from './social'

import styles from './footer.module.css'
import stylesLink from './link-block/footer-link-block.module.css'

export const Footer: FC = () => {
  const { t } = useTranslation()
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.footer__header}>
          <IpokratLogo className={styles['footer__header-logo']} color="white" />
          <a
            className={styles['footer__footer-link']}
            href={`tel:${t('footer.telephone')}`}
          >
            {t('footer.telephone_text')}
          </a>
          <FooterSocial />
        </div>
        <FooterLinkBlock
          title={t('about')}
          links={[{
            href: paths.QUI_SOMMES_NOUS,
            label: t('who_we_are'),
          }]}
        />
        <FooterLinkBlock
          title={t('ressources')}
          links={[
            {
              href: paths.EMPLOYEUR,
              label: t('recrut_offer'),
            }, {
              href: paths.VOS_AVANTAGES,
              label: t('vos_avantages.title'),
            },
          ]}
        />
        <FooterLinkBlock
          title={t('others')}
          links={[{
            href: cguCandidatUrl,
            label: t('CGU_candidat'),
            download: true,
          }, {
            href: cguEmployeurUrl,
            label: t('CGU_employeur'),
            download: true,
          }, {
            href: mentionsLegalesUrl,
            label: t('mentions_legales'),
            download: true,
          }]}
        >
          <li className={stylesLink['footer-link-block__link-li']}>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <a
              href="javascript:showAxeptioButton()"
              className={stylesLink['footer-link-block__link']}
            >
              {t('preferences_cookies')}
            </a>
            {/* eslint-enable jsx-a11y/anchor-is-valid */}
          </li>
        </FooterLinkBlock>
      </div>
      <div className={styles.footer__footer}>
        <hr className={styles.footer__hr} />
        <div className={styles['footer__footer-container']}>
          <a
            className={styles['footer__footer-link']}
            href={`mailto:${t('mail_to_contact')}`}
          >
            {t('contact_us')}
          </a>
          <p className={styles['footer__footer-copyright']}>
            {t('ipokrat_copyright', { date: new Date() })}
          </p>
        </div>
      </div>
    </footer>
  )
}
