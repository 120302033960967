import React, { FC, ReactNode } from 'react'
import cx from 'classnames'

import styles from './appbar.module.css'

export type positionType = 'top' | 'bottom'
export type justifyContentType =
  | 'space-around'
  | 'space-between'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-evenly'

export interface AppBarProps {
  children: ReactNode,
  position?: positionType,
  justifyContent?: justifyContentType
  shadow?: boolean
}

export const AppBar: FC<AppBarProps> = ({
  children,
  position = 'top',
  justifyContent = 'space-around',
  shadow = false,
}) => {
  return (
    <div
      className={cx(styles.appbar, styles[`appbar--${position}`], {
        shadow,
      })}
    >
      <div
        className={styles.appbar__container}
        style={{ justifyContent }}
      >
        {children}
      </div>
    </div>
  )
}
