import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Redirect, useLocation } from 'react-router'
import { paths } from '../../../routing'
import { getLocationDataIdentity, ProfessionsForm, pushHistory } from '../register.helper'
import { ProfessionChat } from '../../../containers/form/form-profession'

export const Profession: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const identity = getLocationDataIdentity(location)

  if (!identity) {
    return <Redirect from={paths.REGISTER_PROFESSION} to={paths.REGISTER_IDENTITY} />
  }

  const onSubmit = (professions: ProfessionsForm) => {
    pushHistory(history, {
      current: paths.REGISTER_PROFESSION,
      next: paths.REGISTER_JOB_CRITERIA,
    }, {
      data: {
        identity,
        professions,
      },
    })
  }

  return (
    <ProfessionChat onSubmit={onSubmit} />
  )
}
