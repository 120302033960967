import React, { FC, ReactNode } from 'react'
import cx from 'classnames'
import styles from './heading.module.css'

export type TextSize = 'xs' | 'sm' | 'base' | 'lg' | '1xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl'

export interface HeadingProps {
  level: 1 | 2 | 3 | 4 | 5 | 6,
  children: ReactNode,
  size?: TextSize,
  mobileSize?: TextSize,
}

export const Heading: FC<HeadingProps> = ({ level, size = 'base', mobileSize = 'base', children }) => {
  switch (level) {
  default:
    return null
  case 1:
    return (<h1 className={cx(styles[`heading--${size}`], styles[`heading--mobile-${mobileSize}`])}>{children}</h1>)
  case 2:
    return (<h2 className={cx(styles[`heading--${size}`], styles[`heading--mobile-${mobileSize}`])}>{children}</h2>)
  case 3:
    return (<h3 className={cx(styles[`heading--${size}`], styles[`heading--mobile-${mobileSize}`])}>{children}</h3>)
  case 4:
    return (<h4 className={cx(styles[`heading--${size}`], styles[`heading--mobile-${mobileSize}`])}>{children}</h4>)
  case 5:
    return (<h5 className={cx(styles[`heading--${size}`], styles[`heading--mobile-${mobileSize}`])}>{children}</h5>)
  case 6:
    return (<h6 className={cx(styles[`heading--${size}`], styles[`heading--mobile-${mobileSize}`])}>{children}</h6>)
  }
}
