import React, { FC, ReactNode } from 'react'
import { Block } from '../block'

import styles from './process-step.module.css'

export interface ProccessStepProps {
  title: string,
  stepsChildren: ReactNode,
  img: {
    src: string,
    alt: string,
    srcset: string[],
  },
  noPadding?: boolean,
}

export const ProccessStep: FC<ProccessStepProps> = ({ title = '', stepsChildren, img, noPadding = false }) => {
  return (
    <Block
      column
      title={title}
      titleAlign="center"
      titleSize="4xl"
      titleMobileSize="3xl"
      sectionPadding={noPadding ?'none' : 'normal'}
    >
      <div className={styles['process-step__content']}>
        <div>{stepsChildren}</div>
        <div
          className={styles['process-step__img']}
        >
          <img
            alt={img.alt}
            src={img.src}
            srcSet={img.srcset.join(',')}
          />
        </div>
      </div>
    </Block>
  )
}
