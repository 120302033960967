import React, { FC } from 'react'
import { Card } from '../../card'

import styles from './card-avantages.module.css'

export interface CardAvantagesProps {
  title: string,
  text: string, 
}

export const CardAvantages: FC<CardAvantagesProps> = ({
  title,
  text,
  children,
}) => {
  return (
    <Card className={styles['card-avantages']}>
      <article>
        <header>
          <h2>
            {title}
          </h2>
          <p>{text}</p>
        </header>
        <section>
          {children}
        </section>
      </article>
    </Card>
  )
}
