import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Hero,
  PropositionValeur,
  PropositionValeurArticle,
  BeneficeRecherches,
  BeneficesRecherchesArticle,
  Fondateurs,
  Identification,
  ClipboardCheck,
  Users,
  HorizontalRuleCommunication,
  TypologieProfession,
  ImageContainer,
  Image,
  Engagement,
  LayoutCommunication,
  ProccessStep,
  VerticalStepper,
  StepperItem,
} from '../../components'
import { paths } from '../../routing'
import { FormHome } from '../../containers/form-home'

/* eslint-disable max-len */
import professionParamedicale from '../../assets/images/professions__paramedicales.jpg'
import professionAdministrative from '../../assets/images/professions__administratives-et-techniques.jpg'
import professionMedicale from '../../assets/images/professions__medicales.jpg'
import professionPharma from '../../assets/images/professions__industrie-pharmaceutique.jpg'
import surfer from '../../assets/images/illustrations/assets-media-illustrations-grey-competences.jpg'
import surfer2x from '../../assets/images/illustrations/assets-media-illustrations-grey-competences@2x.jpg'
import surfer3x from '../../assets/images/illustrations/assets-media-illustrations-grey-competences@3x.jpg'
import stairs from '../../assets/images/illustrations/assets-media-illustrations-grey-recompense.jpg'
import stairs2x from '../../assets/images/illustrations/assets-media-illustrations-grey-recompense@2x.jpg'
import stairs3x from '../../assets/images/illustrations/assets-media-illustrations-grey-recompense@3x.jpg'
import yoga from '../../assets/images/illustrations/assets-media-illustrations-grey-zen.jpg'
import yoga2x from '../../assets/images/illustrations/assets-media-illustrations-grey-zen@2x.jpg'
import yoga3x from '../../assets/images/illustrations/assets-media-illustrations-grey-zen@3x.jpg'
import work from '../../assets/images/illustrations/assets-media-illustrations-white-pret-a-travailler.png'
import work2x from '../../assets/images/illustrations/assets-media-illustrations-white-pret-a-travailler@2x.png'
import work3x from '../../assets/images/illustrations/assets-media-illustrations-white-pret-a-travailler@3x.png'

/* eslint-enable max-len */

export const Home: FC = () => {
  const { t } = useTranslation()

  const steps = [
    {
      step: 1,
      text: t('home.process_step.step1.text'),
    },
    {
      step: 2,
      text: t('home.process_step.step2.text'),
    },
    {
      step: 3,
      text: t('home.process_step.step3.text'),
    },
  ]

  return (
    <LayoutCommunication>
      <Hero
        title={t('home.hero.title')}
        subtitle={t('home.hero.subtitle')}
        type="home"
      />
      <FormHome />
      <PropositionValeur title={t('proposition_valeur_title')}>
        <PropositionValeurArticle text={t('proposition_valeur_text_rapide')} icon={<Identification />} />
        <PropositionValeurArticle text={t('proposition_valeur_text_simple')} icon={<ClipboardCheck />} />
        <PropositionValeurArticle text={t('proposition_valeur_text_convivial')} icon={<Users />} />
      </PropositionValeur>
      <HorizontalRuleCommunication />
      <TypologieProfession title={t('typologie_profession_title')} buttonText={t('find_job')}>
        <ImageContainer>
          <Image
            src={professionMedicale}
            alt={t('alt_image_profession')}
            caption={t('caption_profession_medicale')}
          />
          <Image
            src={professionParamedicale}
            alt={t('alt_image_profession')}
            caption={t('caption_profession_paramedicale')}
          />
          <Image
            src={professionAdministrative}
            alt={t('alt_image_profession')}
            caption={t('caption_profession_administrative')}
          />
          <Image
            src={professionPharma}
            alt={t('alt_image_profession')}
            caption={t('caption_profession_pharma')}
          />
        </ImageContainer>
      </TypologieProfession>
      <BeneficeRecherches title={t('benefices_recherches.title')}>
        <BeneficesRecherchesArticle
          title={t('benefices_recherches.valorisation.title')}
          text={t('benefices_recherches.valorisation.text')}
          img={{
            alt: '',
            src: yoga,
            srcset: [
              `${yoga2x} 2x`,
              `${yoga3x} 3x`,
            ],
          }}
        />
        <BeneficesRecherchesArticle
          reverse={true}
          title={t('benefices_recherches.recompense.title')}
          text={t('benefices_recherches.recompense.text')}
          img={{
            alt: '',
            src: stairs,
            srcset: [
              `${stairs2x} 2x`,
              `${stairs3x} 3x`,
            ],
          }}
          link={{
            href: paths.VOS_AVANTAGES,
            text: t('benefices_recherches.recompense.link'),
          }}
        />
        <BeneficesRecherchesArticle
          title={t('benefices_recherches.choix.title')}
          text={t('benefices_recherches.choix.text')}
          img={{
            alt: '',
            src: surfer,
            srcset: [
              `${surfer2x} 2x`,
              `${surfer3x} 3x`,
            ],
          }}
        />
      </BeneficeRecherches>
      <Fondateurs
        title={t('fondateur_title')}
        text={t('fondateur_text')}
        link={{
          text: t('fondateur_link'),
          href: paths.QUI_SOMMES_NOUS,
        }}
      />
      <ProccessStep
        title={(t('home.process_step.title'))}
        stepsChildren={(
          <VerticalStepper>
            {steps.map( step => <StepperItem key={step.step} step={step.step} text={step.text} />)}
          </VerticalStepper>
        )}
        img={{
          alt: '',
          src: work,
          srcset: [
            `${work2x} 2x`,
            `${work3x} 3x`,
          ],
        }}
      />
      <Engagement
        title={t('engagement_title')}
        text={t('engagement_text')}
        buttonFindJob={t('find_job')}
        buttonRecuitmentOffer={t('recruitment_offer')}
      />
    </LayoutCommunication>
  )
}
