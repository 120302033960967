import React, { FC } from 'react'
import { Skeleton, SkeletonProps } from '../skeleton'

export const CardSkeleton: FC<SkeletonProps> = ({
  children,
  ...props
}) => (
  <Skeleton
    type="light"
    {...props}
    style={{
      borderRadius: '.5rem',
      border: 'var(--default-border)',
      ...props.style,
    }}
  >
    {children}
  </Skeleton>
)
