import React, { FC } from 'react'
import cx from 'classnames'

import styles from './card.module.css'

export interface CardProps {
  className?: string,
  backgroundColor?: 'default' | 'gray',
  margin?: 'default' | 'medium'
}

export const Card: FC<CardProps> = ({
  className = '',
  children,
  backgroundColor,
  margin,
}) => {
  return (
    <section
      className={cx(
        styles.card,
        className, styles[`card--${backgroundColor}`],
        styles[`card-margin--${margin}`])}
    >
      {children}
    </section>
  )
}
