import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { backgroundColorType, marginType, sizeType, paddingType } from '../button/button'

import styles from '../button/button.module.css'
import './buttonlink.css'

export type colorLinkType =  'white' | 'gray'

export interface ButtonLinkProps {
  link: string,
  backgroundColor?: backgroundColorType,
  size?: sizeType,
  children: ReactNode,
  disabled?: boolean,
  margin?: marginType,
  padding?: paddingType,
  testId?: string,
  color?: colorLinkType,
}

export const ButtonLink: FC<ButtonLinkProps> = ({
  link,
  children,
  backgroundColor = 'primary',
  size = 'medium',
  disabled = false,
  margin = 'default',
  padding = 'default',
  testId,
  color = 'white',
}) => {
  const isMailTo = link.includes('mailto')
  const isTelephone = link.includes('tel')
  const classNames = cx(
    styles.button,
    'button-link',
    styles[`button--${backgroundColor}`],
    styles[`button--${size}`],
    styles[`button--margin-${margin}`],
    styles[`button--padding-${padding}`],
    [`button-link--${color}`],
    {
      ['button-link--disabled']: disabled,
    })

  return isMailTo || isTelephone
    ? (
      <a
        href={link}
        className={classNames}
      >
        {children}
      </a>
    ) : (
      <Link
        to={link}
        data-test={testId}
        className={classNames}
      >
        {children}
      </Link>
    )
}
