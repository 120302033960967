import React, { FC, useEffect, useState } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { IpokratLogo } from '../logo'
import { Burger, Cross } from '../icon'
import { Button } from '../form'
import { useMediaQuery } from '../../hooks'

import styles from './header.module.css'

export interface HeaderProps {
  logoLink: string,
  background?: 'white' | 'transparent',
  position?: 'fixed' | 'static',
}

export const Header: FC<HeaderProps> = ({
  logoLink,
  background = 'white',
  position = 'fixed',
  children,
}) => {
  const [open, toggle] = useState<boolean>(false)
  const isDesktop = useMediaQuery('(min-width: 30rem)')

  const toggleMenu = () => toggle(!open)

  useEffect(() => {
    toggle(false)
  }, [isDesktop])

  return (
    <header className={styles[`header--${position}`]}>
      <div
        className={cx(
          styles.header__bar,
          styles[`header__bar--${background}`],
          {
            [styles['header__bar--open']]: open,
            'shadow--thin': position === 'fixed',
          })}
      >
        <div className={styles.header__container}>
          <Link
            to={logoLink}
          >
            <IpokratLogo
              color={
                open || background === 'white'
                  ? 'colored'
                  : background === 'transparent'
                    ? 'colored-white'
                    : 'colored'}
            />
          </Link>
          <nav className={styles['header__nav-desktop']}>
            <ul className={styles['header__menu-desktop']}>
              {children}
            </ul>
          </nav>
          <Button
            className={styles.header__button}
            backgroundColor="none"
            onClick={toggleMenu}
          >
            {open
              ? <Cross />
              : <Burger color={background === 'transparent' ? 'white' : 'dark'} />}
          </Button>
        </div>
      </div>
      <nav
        className={styles['header__nav-mobile']}
        aria-hidden={open ? 'false' : 'true'}
      >
        <div
          className={cx(styles['header__menu-mobile'], {
            [styles['header__menu-mobile--open']]: open,
          })}
        >
          <ul>
            {children}
          </ul>
        </div>
      </nav>
    </header>
  )
}
