import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { isNil } from 'lodash'
import { IdentityForm, getLocationDataIdentity } from '../../register.helper'
import { ChatItem, ChatLayout, Input, ChatItemBubble } from '../../../../components'
import { useStepper } from '../../../../hooks'


export interface FormProps {
  onSubmit: (data: IdentityForm) => void,
}

export const Form: FC<FormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const identity = getLocationDataIdentity(location)
  const { control, errors, handleSubmit, register } = useForm<IdentityForm>({
    defaultValues: {
      firstname: identity?.firstname || '',
      lastname: identity?.lastname || '',
      phone: identity?.phone || '',
    },
  })
  const initStep = !isNil(identity)
    ? (Object.keys(identity || {}).length)
    : 1

  const { step, nextStep, isLastStep, isCurrentState } = useStepper(initStep, 3)

  const onFormSubmit = (data: IdentityForm) => {
    if (isLastStep()) {
      return onSubmit(data)
    }

    nextStep()
  }

  return (
    <form>
      <ChatLayout onSubmit={handleSubmit(onFormSubmit)}>
        <ChatItem avatar>
          <ChatItemBubble fade={isCurrentState(1) ? 'ask' : 'none'}>
            {t('register_first_meet')}
          </ChatItemBubble>
          <ChatItemBubble fade={isCurrentState(1) ? 'ask' : 'none'}>
            {t('register_ask_firstname')}
          </ChatItemBubble>
        </ChatItem>
        <ChatItem position="right">
          <ChatItemBubble fade={isCurrentState(1) ? 'answer' : 'none'}>
            <Controller
              name="firstname"
              control={control}
              rules={{
                required: t('form.error.required') as string,
              }}
              render={props => {
                return (
                  <Input
                    hideLabel={true}
                    name="firstname"
                    type="text"
                    label={t('form.firstname.label')}
                    placeholder={t('form.firstname.label')}
                    onChange={e => props.onChange(e)}
                    value={props.value}
                    error={errors?.firstname?.message}
                  />
                )
              }}
            />
          </ChatItemBubble>
        </ChatItem>
        {step >= 2 && (
          <>
            <ChatItem avatar>
              <ChatItemBubble fade={isCurrentState(2) ? 'ask' : 'none'}>
                {t('register_ask_lastname')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={isCurrentState(2) ? 'answer' : 'none'}>
                <Controller
                  name="lastname"
                  control={control}
                  rules={{
                    required: t('form.error.required') as string,
                  }}
                  render={props => {
                    return (
                      <Input
                        hideLabel={true}
                        name="lastname"
                        type="text"
                        label={t('form.lastname.label')}
                        placeholder={t('form.lastname.label')}
                        onChange={e => props.onChange(e)}
                        value={props.value}
                        error={errors?.lastname?.message}
                      />
                    )
                  }}
                />
              </ChatItemBubble>
            </ChatItem>
          </>
        )}
        {step >= 3 && (
          <>
            <ChatItem avatar>
              <ChatItemBubble fade={isCurrentState(3) ? 'ask' : 'none'}>
                {t('register_ask_phone')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={isCurrentState(3) ? 'answer' : 'none'}>
                <Input
                  type="text"
                  name="phone"
                  hideLabel={true}
                  label={t('form.tel.label')}
                  inputRef={register({
                    required: `${t('form.error.required')}`,
                    pattern: {
                      value: /^(0)[1-9](\d{2}){4}$/,
                      message: t('form.tel.pattern'),
                    },
                  })}
                  error={errors?.phone?.message}
                  inputMode="numeric"
                />
              </ChatItemBubble>
            </ChatItem>
          </>
        )}
      </ChatLayout>
    </form>
  )
}
