import React, { FC, ReactNode, MouseEvent, ButtonHTMLAttributes } from 'react'
import cx from 'classnames'
import { isNil } from 'lodash'
import { Link } from 'react-router-dom'
import { Spinner } from '../../icon'

import styles from './button.module.css'

export type backgroundColorType = 'primary' | 'secondary' | 'tertiary' | 'default' | 'none'
export type sizeType = 'small' | 'medium' | 'large'
export type marginType = 'small' | 'default'
export type paddingType = 'small' | 'default' | 'large' | 'home'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode,
  type?: 'button' | 'submit' | 'reset' | undefined,
  backgroundColor?: backgroundColorType,
  size?: sizeType,
  disabled?: boolean,
  loading?: boolean,
  margin?: marginType,
  padding?: paddingType,
  onClick?: (e: MouseEvent) => void,
  className?: string,
  icon?: ReactNode,
  /** @deprecated */
  testId?: string,
  link?: string,
  externLink?: string,
}

interface ButtonTagProps extends ButtonProps {
  isButton: boolean,
}

const ButtonTag: FC<ButtonTagProps> = (props) => {
  const {
    isButton,
    link,
    externLink,
    ...otherProps
  } = props
  return isButton
    ? (<button {...otherProps} />)
    : !isNil(link) ? (
      <Link
        to={link as string}
        className={props.className}
      >
        {props.children}
      </Link>)
      : (
        <a
          href={externLink as string}
          rel="noreferrer noopener"
          target="_blank"
          className={props.className}
        >
          {props.children}
        </a>
      )
}

export const Button: FC<ButtonProps> = ({
  children,
  type = 'button',
  backgroundColor = 'primary',
  size = 'medium',
  disabled = false,
  loading = false,
  margin = 'default',
  padding = 'default',
  onClick = null,
  className = '',
  icon,
  testId,
  link,
  externLink,
  ...props
}) => {
  const isButton = isNil(link) && isNil(externLink)

  return (
    <ButtonTag
      link={link}
      externLink={externLink}
      isButton={isButton}
      type={type}
      className={
        cx(styles.button,
          styles[`button--${backgroundColor}`],
          styles[`button--${size}`],
          styles[`button--margin-${margin}`],
          styles[`button--padding-${padding}`],
          {
            [styles['button--disabled']]: disabled,
            [styles['button--link']]: !isButton,
          },
          className)}
      disabled={disabled}
      onClick={(e: any) => onClick && onClick(e)}
      data-test={testId}
      {...props}
    >
      <span className={styles.button__container}>
        {children}
      </span>
      {loading
        ? <span className={styles.button__icon}><Spinner /></span>
        : icon
          ? (<span className={styles.button__icon}>{icon}</span>)
          : null}
    </ButtonTag>
  )
}
