import React, { FC } from 'react'
import './caption.css'

export interface CaptionProps {
  caption?: string,
}

export const Caption: FC<CaptionProps> = ({ caption }) => {
  return (
    <figcaption className="caption">
      {caption}
    </figcaption>
  )
}
