import React, { FC } from 'react'
import { BrowserRouter, StaticRouter } from 'react-router-dom'
import { StaticRouterContext } from 'react-router'
import { renderRoutes } from 'react-router-config'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { ApolloClient, ApolloProvider } from '@apollo/client'
import { i18n } from 'i18next'
import Modal from 'react-modal'
import { ToastContainer } from 'react-toastify'
import { I18nextProvider, useSSR, useTranslation } from 'react-i18next'

import { ScrollTop } from '../../components'
import { routes } from '../../routing'
import { apolloClientBrowser } from '../../graphql/client'
import { i18nClient } from '../i18n/'

import closeToast from '../../assets/images/icon/assets-icon-outline-white-x.svg'
import { useTracking } from '../../hooks'
import { FeatureProvider, features } from '../feature-flipping'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const App: FC<{ client: ApolloClient<any> }> = ({ client }) => {
  useTracking()
  return (
    <ApolloProvider client={client}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        draggable={false}
        autoClose={3000}
        closeButton={<img src={closeToast} alt="close" />}
      />
      {renderRoutes(routes)}
    </ApolloProvider>)
}

export const AppBrowser: FC = () => {
  useSSR(window.initialI18nStore, window.initialLanguage)

  Modal.setAppElement('#root')

  return (
    <HelmetProvider>
      <FeatureProvider features={features}>
        <I18nextProvider i18n={i18nClient}>
          <BrowserRouter>
            <ScrollTop />
            <App client={apolloClientBrowser} />
          </BrowserRouter>
        </I18nextProvider>
      </FeatureProvider>
    </HelmetProvider>
  )
}

export type AppServerProps = {
  context: StaticRouterContext;
  url: string;
  isStaging?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  helmetContext: {};
  assets: {
    client: {
      js: string;
      css?: string;
    }
  }
  i18n: i18n;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>;
}

export const AppServer: FC<AppServerProps> = ({
  context,
  url, helmetContext,
  assets,
  i18n,
  client,
  isStaging = false,
}) => {
  const { t } = useTranslation()

  return (
    <I18nextProvider i18n={i18n}>
      <HelmetProvider context={helmetContext}>
        <Helmet>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta charSet='utf-8' />
          <title>Ipokrat</title>
          <link rel="icon" href="favicon.svg" />
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
          <meta name="description" content={t('head.meta.description')} />
          {isStaging && (<meta name="robots" content="noindex" />)}
          {assets.client.css && <link rel="stylesheet" href={assets.client.css} />}
          {assets.client.js && <script src={assets.client.js} defer />}
        </Helmet>
        <StaticRouter context={context} location={url}>
          <App client={client} />
        </StaticRouter>
      </HelmetProvider>
    </I18nextProvider>
  )
}
