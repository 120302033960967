import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  LayoutCommunication,
  Hero,
  Histoire,
  HistoireArticle,
  Engagement,
  Chiffres,
  ChiffresItem,
} from '../../components'

export const QuiSommesNous: FC = () => {
  const { t } = useTranslation()

  return (
    <LayoutCommunication>
      <Hero
        title={t('qui_sommes_nous.hero.title')}
        type="fondateur"
        justifyContent="space-between"
      />
      <Histoire findJob={t('find_job')} recruitmentOffer={t('recruitment_offer')}>
        <HistoireArticle text={t('qui_sommes_nous.proposition_valeur.right')} />
        <HistoireArticle text={t('qui_sommes_nous.proposition_valeur.middle')} />
        <HistoireArticle text={t('qui_sommes_nous.proposition_valeur.left')} />
      </Histoire>
      <Chiffres>
        <ChiffresItem chiffre={2} title={t('qui_sommes_nous.chiffres.item1.title')} />
        <ChiffresItem
          chiffre={20}
          title={t('qui_sommes_nous.chiffres.item2.title')}
          text={t('qui_sommes_nous.chiffres.item2.text')}
          display="left"
          smallerTitle
        />
        <ChiffresItem
          chiffre={200}
          title={t('qui_sommes_nous.chiffres.item3.title')}
          text={t('qui_sommes_nous.chiffres.item3.text')}
          display="left"
          smallerTitle
        />
        <ChiffresItem
          chiffre={2000}
          title={t('qui_sommes_nous.chiffres.item4.title')}
          display="left"
          smallerTitle
        />
        <ChiffresItem
          chiffre={2020}
          title={t('qui_sommes_nous.chiffres.item5.title')}
          color="red"
        />
      </Chiffres>
      <Engagement
        title={t('engagement_title')}
        text={t('engagement_text')}
        buttonFindJob={t('find_job')}
        buttonRecuitmentOffer={t('recruitment_offer')}
      />
    </LayoutCommunication>
  )
}
