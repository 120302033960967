import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CredentialsForm } from '../../register.helper'
import { Button, Checkbox, Input } from '../../../../components'
import { CGU } from '../../../../containers/cgu'

import '../credentials.css'

export interface FormProps {
  onSubmit: (data: CredentialsForm) => void,
  disabled?: boolean,
}

export const Form: FC<FormProps> = ({ onSubmit, disabled = false }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm<CredentialsForm>()

  const onFormSubmit = (data: CredentialsForm) => onSubmit(data)

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="credentials__form" noValidate>
      <h4>
        {t('credentials.title')}
      </h4>

      <Input
        type="email"
        name="email"
        inputRef={register(
          {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: `${t('form.email.error')}`,
            },
            required: `${t('form.error.required')}`,
          },
        )}
        label={t('form.email.label')}
        placeholder={t('form.email.placeholder')}
        error={errors?.email?.message}
        labelGray
        margin
      />
      <Checkbox
        name="acceptCgu"
        label={(
          <>
            {t('form.cgu.label.start')}
            <CGU label={t('form.cgu.link')} className="credentials__cgu-link" />
            {t('form.cgu.label.end')}
          </>
        )}
        inputRef={register({ required: `${t('form.error.required')}` })}
        error={errors?.acceptCgu?.message}
        thinLabel
      />
      <Button
        type="submit"
        disabled={disabled}
        size="small"
        padding="large"
        id="create-account"
      >
        {t('form_button_create_account')}
      </Button>
    </form>
  )
}
