import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionType, SelectInput } from '../../../components/form/input/select-input/select-input'
import { useGetSpecialitesQuery } from '../../../graphql/graphql'

export interface SelectSpecialityProps {
  name: string,
  value?: OptionType,
  profession: string | undefined,
  onChange: (value: OptionType[] | OptionType | undefined) => void,
}

export const SelectSpeciality: FC<SelectSpecialityProps> = ({
  name,
  value,
  profession,
  onChange,
}) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<OptionType[]>()
  const { data, loading } = useGetSpecialitesQuery()

  const computeSpecialites = useCallback((choosenProfessionId: string | undefined) => {
    const professionSpecialites = data?.getSpecialites?.filter(({ professionId }) => {
      return `${professionId}` === choosenProfessionId
    })

    const specialites = professionSpecialites?.map(({ label, externalId }) => (
      {
        value: String(externalId),
        label: t(`professions.${label}`),
      }),
    )
    setOptions(specialites)

    return specialites
  }, [t, data])

  useEffect(() => {
    if (data?.getSpecialites) {
      computeSpecialites(profession)
    }
  }, [data, computeSpecialites, profession, t])

  return loading
    ? null
    : (
      <SelectInput
        label="profession"
        name={name}
        hideLabel={true}
        onChange={onChange}
        value={value}
        className="selectInputSpecialite"
        multiple={true}
        options={options}
        placeholder={t('choose_specialities')}
        width="30rem"
        textAlign="left"
      />
    )
}
