import React, { FC, Children, cloneElement, ReactElement } from 'react'
import cx from 'classnames'
import { ImageProps } from './image'

import styles from './image-container.module.css'

export const ImageContainer: FC = ({ children }) => {
  const childrenAsArray = Children.toArray(children)
  const childrenLength = childrenAsArray.length

  return (
    <div className={styles['image-container']}>
      <div
        className={cx(
          styles['image-container__container'],
          styles[`image-container__container-${childrenLength}`],
        )}
      >
        {Children.map(children, (child, index) => {
          return cloneElement<ImageProps>(child as ReactElement, { position: childrenLength - index })
        })}
      </div>
    </div>
  )
}
