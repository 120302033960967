import React, { FC } from 'react'
import cx from 'classnames'

import styles from './chiffres-item.module.css'

export type colorType = 'gray' | 'red'

export 
type displayType = 'center' | 'left'

export interface ChiffresItemProps {
  chiffre: number,
  title: string,
  text?: string,
  display?: displayType,
  smallerTitle?: boolean,
  color?: colorType,
}

export const ChiffresItem:FC<ChiffresItemProps> = 
  ({
    chiffre,
    title,
    text= '',
    display='center',
    smallerTitle=false,
    color='gray',
  }) => {
    return (
      <article
        className={cx(
          styles['chiffres-item'],
          styles[`chiffres-item--${display}`],
        )}
      >
        <div
          className={cx(
            styles['chiffres-item__chiffre'],
            styles[`chiffres-item__chiffre--${color}`],
            styles[`chiffres-item__chiffre--${display}`],
          )}
        >
          {chiffre}
        </div>
        <div
          className={cx(
            styles['chiffres-item__text'],
            styles[`chiffres-item__text--${display}`],
          )}
        >
          <h2
            className={cx(
              styles[`chiffres-item__text--${color}`],
              {
                [ styles['chiffres-item__title--primary']]: !smallerTitle,
                [ styles['chiffres-item__title--secondary']]: smallerTitle,
              })}
          >
            {title}
          </h2>
          <p className={styles['chiffres-item__content']}>{text}</p>
        </div>
      </article>
    )
  }
