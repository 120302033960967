import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import i18nextHttpMiddleware from 'i18next-http-middleware'
import { options } from './i18n.options'

i18next
  .use(Backend)
  .use(initReactI18next)
  .use(i18nextHttpMiddleware.LanguageDetector)
  .init(options)

export const i18nClient = i18next

