import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../../../components/form/checkbox/checkbox'
import { DomaineTypeEnum } from '../../../graphql/graphql'
import { ProfessionForm } from '../../../pages/register/register.helper'

type DomaineType = 'SOINS' | 'GERIATRIE' | 'SOINS_A_DOMICILE' | 'PETITE_ENFANCE' | 'AUTRE_DOMAINE'

interface CheckboxDomaineProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: any,
  professionInputs?: ProfessionForm
}

export const CheckboxDomaine: FC<CheckboxDomaineProps> = ({ inputRef, professionInputs }) => {
  const { t } = useTranslation()

  const options = [
    { value: DomaineTypeEnum.SOINS, label: t('register.job_criteria.domaine.soins') },
    { value: DomaineTypeEnum.GERIATRIE, label: t('register.job_criteria.domaine.geriatrie') },
    { value: DomaineTypeEnum.SOINS_A_DOMICILE, label: t('register.job_criteria.domaine.soins_domicile') },
    { value: DomaineTypeEnum.PETITE_ENFANCE, label: t('register.job_criteria.domaine.petite_enfance') },
    { value: DomaineTypeEnum.AUTRE_DOMAINE, label: t('register.job_criteria.domaine.autre_domaine') },
  ]

  return (
    <>
      { options.map((option) => (
        <Checkbox
          key={option.value}
          name={option.value}
          label={option.label}
          inputRef={inputRef}
          defaultChecked={professionInputs ? professionInputs[`${option.value}` as DomaineType] : false}
          boldLabel
          testId={`checkbox-${option.value}`}
        />
      ))}
    </>
  )
}
