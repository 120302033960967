import React, { FC } from 'react'
import { Block } from '../block'
import { Button } from '../../form'

import styles from './avantages-block.module.css'

interface AvantagesBlockProps {
  link:{
    text: string,
    href: string,
  }
  subLabels?: string[],
}

export const AvantagesBlock: FC<AvantagesBlockProps> = ({
  link,
  subLabels,
  children,
}) => {
  return (
    <Block className={styles['avantages__block']} color="gray50" column={true}>
      <div className={styles['avantages']}>
        {children}
      </div>
      <div className={styles.avantages__buttons}>
        <Button link={link.href} padding="home">
          {link.text}
        </Button>
      </div>
      {
        subLabels?.map(subLabel => <p key={subLabel}>{subLabel}</p>)
      }
    </Block>
  )
}
