import React, { FC } from 'react'
import { Facebook, Instagram, Linkedin } from '../../icon'

import styles from './footer-social.module.css'

export const FooterSocial: FC = () => (
  <div className={styles.social}>
    <a href="https://www.facebook.com/Ipokrat-101628165330995/" target="_blank" rel="noreferrer"><Facebook /></a>
    <a href="https://www.linkedin.com/company/ipokrat/" target="_blank" rel="noreferrer"><Linkedin /></a>
    <a href="https://www.instagram.com/ipokrat_/" target="_blank" rel="noreferrer"><Instagram /></a>
  </div>
)
