import React, { FC, ReactNode } from 'react'
import cx from 'classnames'
import { Section } from '../section'
import { Title } from '../title'

import './block.css'
import { TextSize } from '../h/heading'

interface BlockProps {
  title?: string,
  className?: string,
  children: ReactNode,
  size?: 'small' | 'default',
  column?: boolean,
  color?: 'none' | 'gray50',
  sectionPadding?: 'none' | 'normal' | 'large' | 'top-bottom',
  titleAlign?: 'default' | 'center',
  titleSize?: TextSize,
  titleMobileSize?: TextSize,
  titleWidth?: 'default' | 'small',
}

export const Block: FC<BlockProps> = ({
  title = null,
  className = '',
  size = 'default',
  column = false,
  color = 'none',
  sectionPadding = 'normal',
  children,
  titleAlign,
  titleSize,
  titleMobileSize,
  titleWidth,
}) => {
  return (
    <div
      className={cx('block', {
        ['block--gray050']: color === 'gray50',
      },
      className,
      )}
    >
      <Section
        padding={sectionPadding}
        size={size}
      >
        {title && (
          <Title align={titleAlign} level={3} size={titleSize} mobileSize={titleMobileSize} width={titleWidth}>
            {title}
          </Title>
        )}
        <div
          className={cx('block-container', {
            ['block-container--column']: column,
          })}
        >
          {children}
        </div>
      </Section>
    </div>
  )
}
