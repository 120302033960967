import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
  postcode: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getCity: Array<Address>;
  getProfessions?: Maybe<Array<ProfessionData>>;
  getSpecialites?: Maybe<Array<SpecialiteData>>;
  isSousFamille: Scalars['Boolean'];
};


export type QueryGetCityArgs = {
  search: Scalars['String'];
};


export type QueryIsSousFamilleArgs = {
  sousFamilleLabel?: Maybe<SousFamilleLabelEnum>;
  professionId?: Maybe<Scalars['String']>;
};

export enum AvailabilityEnum {
  NOW = 'now',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  NEVER = 'never'
}

export enum DomaineTypeEnum {
  SOINS = 'SOINS',
  GERIATRIE = 'GERIATRIE',
  SOINS_A_DOMICILE = 'SOINS_A_DOMICILE',
  PETITE_ENFANCE = 'PETITE_ENFANCE',
  AUTRE_DOMAINE = 'AUTRE_DOMAINE'
}

export enum PosteTypeEnum {
  INTERNE = 'INTERNE',
  CHEF_DE_CLINIQUE = 'CHEF_DE_CLINIQUE',
  CHEF_DE_SERVICE = 'CHEF_DE_SERVICE',
  PRATICIEN_HOSPITALIER = 'PRATICIEN_HOSPITALIER',
  LIBERAL = 'LIBERAL',
  PEU_IMPORTE = 'PEU_IMPORTE'
}

export enum ContractTypeEnum {
  CDI = 'CDI',
  CDD = 'CDD',
  INTERIM = 'INTERIM',
  VACATION = 'VACATION',
  AUTO_ENTREPRENEUR = 'AUTO_ENTREPRENEUR'
}

export enum EtablissementTypeEnum {
  HOPITAL = 'HOPITAL',
  CLINIQUE = 'CLINIQUE',
  EHPAD = 'EHPAD',
  CENTRE_DE_SANTE = 'CENTRE_DE_SANTE',
  CENTRE_DE_SOIN_ET_DE_READAPTATION = 'CENTRE_DE_SOIN_ET_DE_READAPTATION',
  CABINET_MEDICAL = 'CABINET_MEDICAL',
  AUTRES_ETABLISSEMENTS = 'AUTRES_ETABLISSEMENTS'
}

export enum ServiceTypeEnum {
  URGENCES = 'URGENCES',
  ONCONLOGIE = 'ONCONLOGIE',
  SOINS_INTENSIFS = 'SOINS_INTENSIFS',
  SERVICE_GERIATRIE = 'SERVICE_GERIATRIE',
  SOINS_GENERAUX = 'SOINS_GENERAUX',
  PSYCHIATRIE = 'PSYCHIATRIE',
  NEUROLOGIE = 'NEUROLOGIE',
  CHIRURGIE = 'CHIRURGIE',
  MEDECINE_INTERNE = 'MEDECINE_INTERNE',
  MATERNITE = 'MATERNITE',
  ANESTHESIE = 'ANESTHESIE',
  CARDIOLOGIE = 'CARDIOLOGIE',
  REANIMATION = 'REANIMATION',
  UROLOGIE = 'UROLOGIE',
  PEDIATRIE = 'PEDIATRIE',
  AUTRE = 'AUTRE'
}

export type ProfessionInput = {
  wishPositionIds: Array<Maybe<Scalars['String']>>;
};

export type JobCriteriaInput = {
  availability: AvailabilityEnum;
  contractsType: Array<Maybe<ContractTypeEnum>>;
  city: Scalars['String'];
  distance: Scalars['Int'];
};

export type IdentityInput = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phone: Scalars['String'];
};

export type SignUpInput = {
  identity: IdentityInput;
  profession: ProfessionInput;
  serviceTags: Array<Maybe<ServiceTypeEnum>>;
  domaineTags: Array<Maybe<DomaineTypeEnum>>;
  posteTags: Array<Maybe<PosteTypeEnum>>;
  etablissementTags: Array<Maybe<EtablissementTypeEnum>>;
  jobCriteria: JobCriteriaInput;
  acceptCgu: Scalars['Boolean'];
};

export type ProfessionData = {
  __typename?: 'ProfessionData';
  label: Scalars['String'];
  externalId: Scalars['String'];
  sousFamille?: Maybe<Scalars['String']>;
};

export type SpecialiteData = {
  __typename?: 'SpecialiteData';
  label: Scalars['String'];
  professionId: Scalars['String'];
  externalId: Scalars['String'];
};

export enum SousFamilleLabelEnum {
  SOINS = 'SOINS',
  REEDUCATION = 'REEDUCATION',
  APPAREILLAGE_MEDICAL = 'APPAREILLAGE_MEDICAL',
  ASSISTANCE_MEDICALE_ET_TECHNIQUE = 'ASSISTANCE_MEDICALE_ET_TECHNIQUE',
  MEDECIN = 'MEDECIN',
  DENTISTE = 'DENTISTE',
  SAGE_FEMME = 'SAGE_FEMME',
  PHARMACIEN = 'PHARMACIEN',
  PROFESSION_ADMINISTRATIVE_ET_TECHNIQUE = 'PROFESSION_ADMINISTRATIVE_ET_TECHNIQUE'
}

export type Mutation = {
  __typename?: 'Mutation';
  signUp: Scalars['Boolean'];
};


export type MutationSignUpArgs = {
  signUpInput: SignUpInput;
};

export type GetCityQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type GetCityQuery = (
  { __typename?: 'Query' }
  & { getCity: Array<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'name' | 'postcode'>
  )> }
);

export type IsSousFamilleQueryVariables = Exact<{
  sousFamilleLabel?: Maybe<SousFamilleLabelEnum>;
  professionId?: Maybe<Scalars['String']>;
}>;


export type IsSousFamilleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isSousFamille'>
);

export type GetProfessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfessionsQuery = (
  { __typename?: 'Query' }
  & { getProfessions?: Maybe<Array<(
    { __typename?: 'ProfessionData' }
    & Pick<ProfessionData, 'label' | 'externalId' | 'sousFamille'>
  )>> }
);

export type GetSpecialitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpecialitesQuery = (
  { __typename?: 'Query' }
  & { getSpecialites?: Maybe<Array<(
    { __typename?: 'SpecialiteData' }
    & Pick<SpecialiteData, 'label' | 'professionId' | 'externalId'>
  )>> }
);

export type SignUpMutationVariables = Exact<{
  signUpInput: SignUpInput;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signUp'>
);


export const GetCityDocument = gql`
    query getCity($search: String!) {
  getCity(search: $search) {
    id
    name
    postcode
  }
}
    `;

/**
 * __useGetCityQuery__
 *
 * To run a query within a React component, call `useGetCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCityQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetCityQuery(baseOptions: Apollo.QueryHookOptions<GetCityQuery, GetCityQueryVariables>) {
        return Apollo.useQuery<GetCityQuery, GetCityQueryVariables>(GetCityDocument, baseOptions);
      }
export function useGetCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCityQuery, GetCityQueryVariables>) {
          return Apollo.useLazyQuery<GetCityQuery, GetCityQueryVariables>(GetCityDocument, baseOptions);
        }
export type GetCityQueryHookResult = ReturnType<typeof useGetCityQuery>;
export type GetCityLazyQueryHookResult = ReturnType<typeof useGetCityLazyQuery>;
export type GetCityQueryResult = Apollo.QueryResult<GetCityQuery, GetCityQueryVariables>;
export const IsSousFamilleDocument = gql`
    query isSousFamille($sousFamilleLabel: SousFamilleLabelEnum, $professionId: String) {
  isSousFamille(sousFamilleLabel: $sousFamilleLabel, professionId: $professionId)
}
    `;

/**
 * __useIsSousFamilleQuery__
 *
 * To run a query within a React component, call `useIsSousFamilleQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSousFamilleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSousFamilleQuery({
 *   variables: {
 *      sousFamilleLabel: // value for 'sousFamilleLabel'
 *      professionId: // value for 'professionId'
 *   },
 * });
 */
export function useIsSousFamilleQuery(baseOptions?: Apollo.QueryHookOptions<IsSousFamilleQuery, IsSousFamilleQueryVariables>) {
        return Apollo.useQuery<IsSousFamilleQuery, IsSousFamilleQueryVariables>(IsSousFamilleDocument, baseOptions);
      }
export function useIsSousFamilleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsSousFamilleQuery, IsSousFamilleQueryVariables>) {
          return Apollo.useLazyQuery<IsSousFamilleQuery, IsSousFamilleQueryVariables>(IsSousFamilleDocument, baseOptions);
        }
export type IsSousFamilleQueryHookResult = ReturnType<typeof useIsSousFamilleQuery>;
export type IsSousFamilleLazyQueryHookResult = ReturnType<typeof useIsSousFamilleLazyQuery>;
export type IsSousFamilleQueryResult = Apollo.QueryResult<IsSousFamilleQuery, IsSousFamilleQueryVariables>;
export const GetProfessionsDocument = gql`
    query getProfessions {
  getProfessions {
    label
    externalId
    sousFamille
  }
}
    `;

/**
 * __useGetProfessionsQuery__
 *
 * To run a query within a React component, call `useGetProfessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfessionsQuery(baseOptions?: Apollo.QueryHookOptions<GetProfessionsQuery, GetProfessionsQueryVariables>) {
        return Apollo.useQuery<GetProfessionsQuery, GetProfessionsQueryVariables>(GetProfessionsDocument, baseOptions);
      }
export function useGetProfessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfessionsQuery, GetProfessionsQueryVariables>) {
          return Apollo.useLazyQuery<GetProfessionsQuery, GetProfessionsQueryVariables>(GetProfessionsDocument, baseOptions);
        }
export type GetProfessionsQueryHookResult = ReturnType<typeof useGetProfessionsQuery>;
export type GetProfessionsLazyQueryHookResult = ReturnType<typeof useGetProfessionsLazyQuery>;
export type GetProfessionsQueryResult = Apollo.QueryResult<GetProfessionsQuery, GetProfessionsQueryVariables>;
export const GetSpecialitesDocument = gql`
    query getSpecialites {
  getSpecialites {
    label
    professionId
    externalId
  }
}
    `;

/**
 * __useGetSpecialitesQuery__
 *
 * To run a query within a React component, call `useGetSpecialitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecialitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecialitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpecialitesQuery(baseOptions?: Apollo.QueryHookOptions<GetSpecialitesQuery, GetSpecialitesQueryVariables>) {
        return Apollo.useQuery<GetSpecialitesQuery, GetSpecialitesQueryVariables>(GetSpecialitesDocument, baseOptions);
      }
export function useGetSpecialitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpecialitesQuery, GetSpecialitesQueryVariables>) {
          return Apollo.useLazyQuery<GetSpecialitesQuery, GetSpecialitesQueryVariables>(GetSpecialitesDocument, baseOptions);
        }
export type GetSpecialitesQueryHookResult = ReturnType<typeof useGetSpecialitesQuery>;
export type GetSpecialitesLazyQueryHookResult = ReturnType<typeof useGetSpecialitesLazyQuery>;
export type GetSpecialitesQueryResult = Apollo.QueryResult<GetSpecialitesQuery, GetSpecialitesQueryVariables>;
export const SignUpDocument = gql`
    mutation signUp($signUpInput: SignUpInput!) {
  signUp(signUpInput: $signUpInput)
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      signUpInput: // value for 'signUpInput'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;