import React, { FC } from 'react'
import styles from './circle.module.css' 

export interface CircleProps {
  step: number,
}

export const Circle:FC<CircleProps> = ({ step = 1 }) => {
  return (
    <div className={styles.circle}>{step}</div>
  )
}
