import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Button, Card, HorizontalRule, Input, VerticalRule } from '../../components'
import { pushHistory, RegisterStepperStateData } from '../../pages/register/register.helper'
import { paths } from '../../routing'
import { useMediaQuery } from '../../hooks/use-media-query'

import { SelectProfessionHome } from '../input'
import { OptionType } from '../../components/form/input/select-input/select-input'
import { useIsSousFamille } from '../../hooks/use-is-sous-famille'
import { SousFamilleLabelEnum } from '../../graphql/graphql'
import styles from './form-home.module.css'


interface SelectHomeForm {
  firstname: string,
  profession: OptionType,
}

const getData = (data: SelectHomeForm, isSoin?: boolean) => {

  return data.firstname.length > 0
    ? {
      identity: { firstname: data.firstname },
      professions: {
        professionInputs: [{
          wishPositionId: data.profession,
          isSoin,
        }],
      },
    }
    : undefined
}

export const FormHome: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { checkSousFamille, isSousFamille: isSoin } = useIsSousFamille()
  const { handleSubmit, register, control } = useForm<SelectHomeForm>()
  const isDesktop = useMediaQuery('(min-width: 30rem)')

  const handlerChangeWishPosition = (wishPosition: OptionType) => {
    checkSousFamille(SousFamilleLabelEnum.SOINS, wishPosition.value)
  }

  const onSubmit = (data: SelectHomeForm) => {

    pushHistory(history, {
      current: paths.HOME,
      next: paths.REGISTER_IDENTITY,
    }, {
      data: getData(data, isSoin) as RegisterStepperStateData,
    })
  }

  return (
    <div className={styles['form-home']}>
      <Card className={styles['form-home__container']}>
        <form className={styles['form-home__form']}>
          <Input
            name="firstname"
            type="text"
            label={t('register_ask_firstname')}
            labelDark
            placeholder={t('form.firstname.placeholder')}
            home={true}
            inputRef={register()}
          />
          {isDesktop ?  <VerticalRule /> : <HorizontalRule />}
          <Controller
            name="profession"
            control={control}
            defaultValue=""
            render={props =>
              (
                <SelectProfessionHome
                  name={props.name}
                  onChange={(e) => {
                    props.onChange(e)
                    handlerChangeWishPosition(e as OptionType)
                  }}
                  value={props.value}
                />
              )}
          />
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            padding="home"
            testId="find-job"
          >
            {t('home.form.send')}
          </Button>
        </form>
      </Card>
    </div>
  )
}
