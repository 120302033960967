import { RouteConfig } from 'react-router-config'
import {
  Home,
  Register,
  NotFound,
  QuiSommesNous,
  Employeur,
  VosAvantages,
} from './pages'
import { Identity } from './pages/register/identity'
import { JobCriteria } from './pages/register/job-criteria'
import { Credentials } from './pages/register/credentials'
import { Profession } from './pages/register/profession'

export const paths = {
  HOME: '/',
  QUI_SOMMES_NOUS: '/notre-histoire',
  EMPLOYEUR: '/recruter-du-personnel',
  VOS_AVANTAGES: '/vos-avantages',
  REGISTER: '/inscription',
  REGISTER_IDENTITY: '/inscription/identite',
  REGISTER_PROFESSION: '/inscription/job-recherche',
  REGISTER_JOB_CRITERIA: '/inscription/criteres-recherche',
  REGISTER_CREDENTIALS: '/inscription/identifiants',
}

/**
 * FIXME ADD CODE SPLITTING & LAZY LOAD
 * @link https://github.com/jaredpalmer/razzle/tree/master/examples/with-loadable-components
 */

export const routes: RouteConfig[] = [
  {
    path: paths.HOME,
    exact: true,
    component: Home,
  }, {
    path: paths.QUI_SOMMES_NOUS,
    exact: true,
    component: QuiSommesNous,
  }, {
    path: paths.VOS_AVANTAGES,
    exact: true,
    component: VosAvantages,
  }, {
    path: paths.EMPLOYEUR,
    exact: true,
    component: Employeur,
  }, {
    path: paths.REGISTER,
    component: Register,
    routes: [{
      path: paths.REGISTER_IDENTITY,
      component: Identity,
    }, {
      path: paths.REGISTER_PROFESSION,
      component: Profession,
    }, {
      path: paths.REGISTER_JOB_CRITERIA,
      component: JobCriteria,
    }, {
      path: paths.REGISTER_CREDENTIALS,
      component: Credentials,
    }],
  },
  {
    component: NotFound,
  },
]
