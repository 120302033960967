import React from 'react'
import { hydrate } from 'react-dom'

import reportWebVitals from './report-web-vitals'
import { AppBrowser } from './containers/app'

import 'react-toastify/dist/ReactToastify.min.css'

import './styles/variables.css'
import './styles/styles.css'
import './styles/animations.css'
import './styles/toast.css'

/**
 * FIXME ADD CODe SPLITTING & LAZY LOAD
 * @link https://github.com/jaredpalmer/razzle/blob/master/examples/with-loadable-components/src/client.js
 */
hydrate(
  <AppBrowser />,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
