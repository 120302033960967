import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import {
  getLocationDataIdentity,
  pushHistory,
  JobCriteriaForm,
  getLocationDataProfession,
} from '../register.helper'
import { paths } from '../../../routing'
import { Form } from '../../../containers/form/form-criteria'

export const JobCriteria: FC = () => {
  const history = useHistory()
  const location = useLocation()

  const identity = getLocationDataIdentity(location)
  const professions = getLocationDataProfession(location)

  const onSubmit = (jobCriteria: JobCriteriaForm) => {
    pushHistory(history, {
      current: paths.REGISTER_JOB_CRITERIA,
      next: paths.REGISTER_CREDENTIALS,
    }, {
      data: {
        identity,
        professions,
        jobCriteria,
      },
    })
  }

  return (
    <Form onSubmit={onSubmit} />
  )
}
