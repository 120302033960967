import React, { FC, ReactNode } from 'react'
import { Block } from '../block/block'
import { EngagementButtons } from '../engagement/engagement-buttons/engagement-buttons'
import styles from './histoire.module.css'

interface HistoireProps {
  children?: ReactNode,
  findJob: string,
  recruitmentOffer: string,
}

export const Histoire: FC<HistoireProps> = ({ children, findJob, recruitmentOffer }) => {
  return (
    <Block column={true}>
      <div className={styles.histoire}>
        {children}
      </div>
      <EngagementButtons firstButton={findJob} secondButton={recruitmentOffer} />
    </Block>
  )
}
