import * as H from 'history'
import { getOr, flow } from 'lodash/fp'
import { OptionType } from '../../components/form/input/select-input/select-input'
import {
  ContractTypeEnum,
  DomaineTypeEnum,
  EtablissementTypeEnum,
  PosteTypeEnum,
  ServiceTypeEnum,
} from '../../graphql/graphql'

export const getState = getOr(null, 'state')
export const getData = getOr(null, 'data')
export const getIdentity = getOr(null, 'identity')
export const getProfession = getOr(null, 'professions')
export const getJobCriteria = getOr(null, 'jobCriteria')
export const getLocationData: (location: H.Location) => RegisterStepperStateData = flow([getState, getData])
export const getLocationDataIdentity: (location: H.Location) =>
  IdentityForm = flow([getState, getData, getIdentity])
export const getLocationDataProfession: (location: H.Location) =>
  ProfessionsForm = flow([getState, getData, getProfession])
export const getLocationDataJobCriteria: (location: H.Location) =>
  JobCriteriaForm = flow([getState, getData, getJobCriteria])

export interface IdentityForm {
  firstname: string,
  lastname: string,
  phone: string,
}

export interface ProfessionForm {
  wishPositionId: OptionType,
  hasSpecialite?: OptionType,
  specialites?: OptionType[],
  serviceTags?: OptionType[],
  isSoin?: boolean,
  SOINS?: boolean,
  GERIATRIE?: boolean,
  SOINS_A_DOMICILE?: boolean,
  PETITE_ENFANCE?: boolean,
  AUTRE_DOMAINE?: boolean,
  CHEF_DE_CLINIQUE?: boolean,
  CHEF_DE_SERVICE?: boolean,
  INTERNE?: boolean,
  LIBERAL?: boolean,
  PRATICIEN_HOSPITALIER?: boolean,
  PEU_IMPORTE?: boolean,
  CENTRE_DE_SANTE?: boolean,
  CABINET_MEDICAL?: boolean,
  CENTRE_DE_SOIN_ET_DE_READAPTATION?: boolean,
  CLINIQUE?: boolean,
  EHPAD?: boolean,
  HOPITAL?: boolean,
  AUTRES_ETABLISSEMENTS?: boolean,
}

export interface ProfessionsForm {
  wishPositionIds: OptionType[],
  professionInputs?: ProfessionForm[]
}

export interface Specialite {
  professionId: string,
  label: string,
  externalId: string,
}

export interface JobCriteriaForm {
  availability: OptionType,
  city: OptionType,
  distance: OptionType,
  CDI: boolean,
  CDD: boolean,
  INTERIM: boolean,
  VACATION: boolean,
  AUTO_ENTREPRENEUR: boolean,
}

export interface CredentialsForm {
  email: string,
  password: string,
  acceptCgu: boolean,
}

export interface RegisterStepperStateData {
  identity?: IdentityForm,
  credentials?: CredentialsForm,
  professions?: ProfessionsForm,
  jobCriteria?: JobCriteriaForm,
}

export interface RegisterStepperState {
  data?: RegisterStepperStateData,
}

export const pushHistory = (
  history: H.History,
  pathname: {
    current: string,
    next: string,
  },
  { data }: RegisterStepperState,
) => {
  history.replace(pathname.current, { data })
  history.push({
    pathname: pathname.next,
    state: {
      data,
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOptionType = (option: any): option is OptionType =>
  (option as OptionType).value !== undefined && (option as OptionType).label !== undefined

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOptionTypeArray = (options: any): options is OptionType[] =>
  options.map((option: OptionType) => isOptionType(option)) && options.length !== 0

export  const getContractsType = (data: JobCriteriaForm): ContractTypeEnum[] => {
  const contractsType = []
  if(data.CDI) contractsType.push(ContractTypeEnum.CDI)
  if(data.CDD) contractsType.push(ContractTypeEnum.CDD)
  if(data.INTERIM) contractsType.push(ContractTypeEnum.INTERIM)
  if(data.VACATION) contractsType.push(ContractTypeEnum.VACATION)
  if(data.AUTO_ENTREPRENEUR) contractsType.push(ContractTypeEnum.AUTO_ENTREPRENEUR)

  return contractsType
}

export  const getDomaineType = (data?: ProfessionForm): DomaineTypeEnum[] => {
  const domaineType = []

  if (data) {
    if(data.SOINS) domaineType.push(DomaineTypeEnum.SOINS)
    if(data.SOINS_A_DOMICILE) domaineType.push(DomaineTypeEnum.SOINS_A_DOMICILE)
    if(data.GERIATRIE) domaineType.push(DomaineTypeEnum.GERIATRIE)
    if(data.PETITE_ENFANCE) domaineType.push(DomaineTypeEnum.PETITE_ENFANCE)
    if(data.AUTRE_DOMAINE) domaineType.push(DomaineTypeEnum.AUTRE_DOMAINE)
  }

  return domaineType
}

export  const getPosteType = (data?: ProfessionForm): PosteTypeEnum[] => {
  const posteType = []

  if (data) {
    if(data.CHEF_DE_SERVICE) posteType.push(PosteTypeEnum.CHEF_DE_SERVICE)
    if(data.CHEF_DE_CLINIQUE) posteType.push(PosteTypeEnum.CHEF_DE_CLINIQUE)
    if(data.LIBERAL) posteType.push(PosteTypeEnum.LIBERAL)
    if(data.INTERNE) posteType.push(PosteTypeEnum.INTERNE)
    if(data.PRATICIEN_HOSPITALIER) posteType.push(PosteTypeEnum.PRATICIEN_HOSPITALIER)
    if(data.PEU_IMPORTE) posteType.push(PosteTypeEnum.PEU_IMPORTE)
  }

  return posteType
}

export  const getEtablissementType = (data?: ProfessionForm): EtablissementTypeEnum[] => {
  const posteType = []

  if (data) {
    if(data.CENTRE_DE_SANTE) posteType.push(EtablissementTypeEnum.CENTRE_DE_SANTE)
    if(data.CABINET_MEDICAL) posteType.push(EtablissementTypeEnum.CABINET_MEDICAL)
    if(data.CENTRE_DE_SOIN_ET_DE_READAPTATION) posteType.push(EtablissementTypeEnum.CENTRE_DE_SOIN_ET_DE_READAPTATION)
    if(data.EHPAD) posteType.push(EtablissementTypeEnum.EHPAD)
    if(data.HOPITAL) posteType.push(EtablissementTypeEnum.HOPITAL)
    if(data.CLINIQUE) posteType.push(EtablissementTypeEnum.CLINIQUE)
    if(data.AUTRES_ETABLISSEMENTS) posteType.push(EtablissementTypeEnum.AUTRES_ETABLISSEMENTS)
  }

  return posteType
}

export  const getServiceType = (data: OptionType[] = []): ServiceTypeEnum[] => {
  return data?.map(({ value }) => value as ServiceTypeEnum)
}
