import React, { FC } from 'react'

import whiteLogo from '../../assets/images/logo/assets-logo-logo-ipokrat-white-and-text-white.svg'
import colorLogo from '../../assets/images/logo/assets-logo-logo-ipokrat-colored-and-text-gray.svg'
import colorLogoWhiteText from '../../assets/images/logo/logo-ipokrat__colored-and-text-white.svg'
import grayLogo from '../../assets/images/logo/assets-logo-logo-ipokrat-silver-and-text-silver.svg'

const getAsset = (color: ColorLogo): string => {
  switch (color) {
  case 'white':
    return whiteLogo
  case 'colored':
    return colorLogo
  case 'colored-white':
    return colorLogoWhiteText
  case 'silver':
    return grayLogo
  default:
    return ''
  }
}

type ColorLogo = 'white' | 'colored' | 'colored-white' | 'silver'

export interface IpokratLogoProps {
  className?: string,
  color?: ColorLogo,
}

export const IpokratLogo: FC<IpokratLogoProps> = ({ color = 'colored', className = '' }) => {
  return (
    <div className={className}>
      <img src={getAsset(color)} alt="Ipokrat logo" />
    </div>
  )
}
