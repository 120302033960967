import React, { FC } from 'react'
import { HorizontalRule } from '../../horizontal-rule'

import './horizontal-rule.css'

export const HorizontalRuleCommunication: FC = () => {
  return (
    <div className="horizontal-rule-communication">
      <HorizontalRule />
    </div>
  )
}
