import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonLink } from '../../form'
import { paths } from '../../../routing'
import { Block } from '../block'
import errorNotFound from '../../../assets/images/error-404.svg'
import styles from './not-found-block.module.css'

export const NotFoundBlock: FC = () => {
  const { t } = useTranslation()

  return (
    <Block sectionPadding="normal" size="small">
      <div className={styles['not-found-block']}>
        <img src={errorNotFound} alt="Error 404" />
        <p className={styles['not-found-block__text']}>{t('not_found.content')}</p>
        <div className={styles['not-found-block__button']}>
          <ButtonLink
            link={paths.HOME}
            margin="small"
            backgroundColor="default"
            padding="home"
            testId="not-found-button"
          >
            {t('not_found.return_home')}
          </ButtonLink>
        </div>
      </div>
    </Block>
  )
}
