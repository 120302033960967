import React, { FC } from 'react'
import { Icon } from '../icon'

import svg from '../../../assets/images/icon/assets-icon-outline-menu.svg'
import svgDark from '../../../assets/images/icon/assets-icon-outline-menu--dark.svg'

export interface BurgerProps {
  color?: 'white' | 'dark'
}

export const Burger: FC<BurgerProps> = ({ color= 'white' }) => {

  return (<Icon src={color === 'dark' ? svgDark : svg} />)
}

