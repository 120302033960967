import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionType, SelectInput } from '../../../components/form/input/select-input/select-input'

export interface SelectServiceProps {
  name: string,
  value?: OptionType,
  onChange: (value: OptionType[] | OptionType | undefined) => void,
}

export const SelectService: FC<SelectServiceProps> = ({ name, value, onChange }) => {
  const { t } = useTranslation()

  const options = [
    { value: 'URGENCES', label: t('register.job_criteria.service_tag.urgences') },
    { value: 'ONCONLOGIE', label: t('register.job_criteria.service_tag.onconlogie') },
    { value: 'SOINS_INTENSIFS', label: t('register.job_criteria.service_tag.soins_intensifs') },
    { value: 'SOINS_GENERAUX', label: t('register.job_criteria.service_tag.soins_generaux') },
    { value: 'SERVICE_GERIATRIE', label: t('register.job_criteria.service_tag.geriatrie') },
    { value: 'PSYCHIATRIE', label: t('register.job_criteria.service_tag.psychiatrie') },
    { value: 'NEUROLOGIE', label: t('register.job_criteria.service_tag.neurologie') },
    { value: 'CHIRURGIE', label: t('register.job_criteria.service_tag.chirurgie') },
    { value: 'MEDECINE_INTERNE', label: t('register.job_criteria.service_tag.medecine_interne') },
    { value: 'MATERNITE', label: t('register.job_criteria.service_tag.maternite') },
    { value: 'ANESTHESIE', label: t('register.job_criteria.service_tag.anesthesie') },
    { value: 'CARDIOLOGIE', label: t('register.job_criteria.service_tag.cardiologie') },
    { value: 'REANIMATION', label: t('register.job_criteria.service_tag.reanimation') },
    { value: 'UROLOGIE', label: t('register.job_criteria.service_tag.urologie') },
    { value: 'PEDIATRIE', label: t('register.job_criteria.service_tag.pediatrie') },
    { value: 'AUTRE', label: t('register.job_criteria.service_tag.autre') },
  ]

  return (
    <SelectInput
      label={t('service_tag.label')}
      name={name}
      hideLabel={true}
      onChange={onChange}
      value={value}
      className="selectInputService"
      options={options}
      placeholder={t('register.job_criteria.service_tag.placeholder')}
      textAlign="left"
      multiple={true}
      width="30rem"
    />
  )
}
