import fetch from 'cross-fetch'
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat } from '@apollo/client'
import { runtimeConfig } from '../config'
import { isBrowser } from '../helpers/environnment'

const getEnv = (name: string) => runtimeConfig.ENV === name

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const options: any = {
  uri: runtimeConfig.GRAPHQL_ENDPOINT,
  fetch,
  credentials: 'include',
}

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: `Bearer ${window.token || null}`,
    },
  })

  return forward(operation)
})

export const apolloClient = (jwt?: string) => {
  const link = new HttpLink({
    ...options,
    headers: {
      cookie: `IPOKRAT_AUTH=${jwt}`,
      'x-authorization': jwt,
    },
  })

  return new ApolloClient({
    ssrMode: true,
    uri: runtimeConfig.GRAPHQL_ENDPOINT,
    link: getEnv('staging')
      ? concat(authMiddleware, link)
      : link,
    cache: new InMemoryCache(),
  })
}

export const apolloClientBrowser = new ApolloClient({
  ssrMode: false,
  uri: runtimeConfig.GRAPHQL_ENDPOINT,
  link: getEnv('staging')
    ? concat(authMiddleware, new HttpLink(options))
    : new HttpLink(options),
  cache: isBrowser
    ? new InMemoryCache().restore(window.__APOLLO_STATE__)
    : new InMemoryCache(),
})
