export const runtimeConfig =
  typeof window !== 'undefined'
    ? {
      // client
      ENV: window.env.ENV,
      STAGE: window.env.STAGE,
      LOCALES_ENDPOINT: window.env.LOCALES_ENDPOINT,
      GRAPHQL_ENDPOINT: window.env.GRAPHQL_ENDPOINT,
      GA_MEASUREMENT_ID: window.env.GA_MEASUREMENT_ID,
      AXEPTIO_ID: window.env.AXEPTIO_ID,
      GTM_ID: window.env.GTM_ID,
      LINKEDIN_PARTNER_ID: window.env.LINKEDIN_PARTNER_ID,
      TROOPS_URL: window.env.TROOPS_URL,
    }
    : {
      // server
      ENV: process.env.RAZZLE_ENV,
      STAGE: process.env.RAZZLE_STAGE,
      LOCALES_ENDPOINT: process.env.LOCALES_ENDPOINT,
      GRAPHQL_ENDPOINT: process.env.GRAPHQL_ENDPOINT,
      GA_MEASUREMENT_ID: process.env.GA_MEASUREMENT_ID,
      AXEPTIO_ID: process.env.AXEPTIO_ID,
      GTM_ID: process.env.GTM_ID,
      LINKEDIN_PARTNER_ID: process.env.LINKEDIN_PARTNER_ID,
      TROOPS_URL: process.env.TROOPS_URL,
    }
