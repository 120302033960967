import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router'
import { isNil, head } from 'lodash'
import { AvailabilityEnum, useSignUpMutation } from '../../../graphql/graphql'
import {
  CredentialsForm,
  getLocationDataIdentity,
  getLocationDataProfession,
  getLocationDataJobCriteria,
  getContractsType, getServiceType, getDomaineType, getPosteType, getEtablissementType,
} from '../register.helper'
import { paths } from '../../../routing'
import { notifyError } from '../../../helpers/toast.helper'
import { sendEvent, USER_CATEGORY, USER_ACTION } from '../../../helpers/analitics.helper'
import { runtimeConfig } from '../../../config'
import { Form } from './form'

export const Credentials: FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const [signUp, { loading, error, data }] = useSignUpMutation(
    {
      onCompleted() {
        sendEvent({
          category: USER_CATEGORY,
          action: USER_ACTION,
        })
      },
    },
  )
  const identity = getLocationDataIdentity(location)
  const professions = getLocationDataProfession(location)
  const wishPositionIds = professions?.wishPositionIds.map(({ value }) => value)
  const serviceTags = head(professions?.professionInputs)?.serviceTags
  const jobCriteria = getLocationDataJobCriteria(location)

  useEffect(() => {
    if (error) {
      notifyError(t(`error.${error.message}`))
    }
  }, [error, t])

  if ([identity, professions, wishPositionIds].every(isNil)) {
    return <Redirect from={paths.REGISTER_CREDENTIALS} to={paths.REGISTER_IDENTITY} />
  }

  if (data?.signUp) {
    return (
      <>
        <h5>
          {t('registration.finished')}
        </h5>
      </>
    )
  }

  const onSubmit = (data: CredentialsForm) => {
    signUp({
      variables: {
        signUpInput: {
          identity: {
            ...identity,
            email: data.email,
          },
          profession: {
            wishPositionIds,
          },
          jobCriteria: {
            availability: jobCriteria.availability.value as AvailabilityEnum,
            contractsType: getContractsType(jobCriteria),
            city: jobCriteria.city.value,
            distance: parseInt(jobCriteria.distance.value, 10),
          },
          serviceTags: getServiceType(serviceTags),
          domaineTags: getDomaineType(head(professions?.professionInputs)),
          posteTags: getPosteType(head(professions?.professionInputs)),
          etablissementTags: getEtablissementType(head(professions?.professionInputs)),
          acceptCgu: data.acceptCgu,
        },
      },
    })
  }

  return (
    <>
      <Form onSubmit={onSubmit} disabled={loading} />
      <div className="credentials__login">
        <a className="credentials__login-link" href={`${runtimeConfig.TROOPS_URL}`}>
          {t('credentials.links.login')}
        </a>
      </div>
    </>
  )
}
