import React, { FC } from 'react'
import cx from 'classnames'
import styles from './vertical-stepper.module.css'

export interface VerticalStepperProps {
  hover?: boolean
}

export const VerticalStepper: FC<VerticalStepperProps> = ({ hover=false, children }) => {
  return (
    <ul
      className={
        cx(styles['vertical-stepper'],
          {
            [styles['vertical-stepper--hover']]: hover,
          },
        )}
    >
      {children}
    </ul>
  )
}
