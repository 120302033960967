import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import styles from './item.module.css'

export type backgroundItemType = 'tertiary' | 'none'

export interface HeaderItemProps {
  href: string,
  background?: backgroundItemType,
  isExternal?: boolean,
}

export const HeaderItem: FC<HeaderItemProps> = ({ href, background = 'none', children, isExternal = false }) => {
  return (
    <li
      className={cx(styles['header-item'], {
        [styles['header-item--tertiary']]: background === 'tertiary',
      })}
    >
      {isExternal && (
        <a href={href} target="_blank" rel="noreferrer" className={styles['header-item__link']}>
          {children}
        </a>
      )}
      {!isExternal && (
        <Link
          to={href}
          className={styles['header-item__link']}
        >
          {children}
        </Link>
      )}
    </li>
  )
}
