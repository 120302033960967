import React, { FC } from 'react'
import cx from 'classnames'
import Select from 'react-select'
import { StylesConfig } from 'react-select/src/styles'
import styles from './select-input.module.css'

export interface OptionType {
  label: string,
  value: string,
}

type IsMulti = boolean

export interface SelectInputProps {
  label: string,
  name: string,
  hideLabel?: boolean,
  options?: OptionType[],
  search?: boolean,
  placeholder: string,
  multiple?: boolean,
  className?: string,
  value?: OptionType,
  onChange: (value: OptionType | OptionType[] | undefined) => void,
  onInputChange?: (value: string) => void,
  width?: string,
  textAlign: string,
  separator?: boolean,
  defaultValue?: OptionType,
  noOptionsMessage?: string,
  margin?: boolean,
  labelGray?: boolean,
  labelDark?: boolean,
}

export const SelectInput: FC<SelectInputProps> = ({
  label,
  name,
  hideLabel = false,
  options = [{ label: '', value: '' }],
  search = true,
  placeholder,
  multiple = false,
  className = '',
  onChange,
  onInputChange,
  width,
  textAlign,
  value,
  defaultValue,
  separator = false,
  noOptionsMessage = null,
  margin = false,
  labelGray = false,
  labelDark = false,
}) => {
  const onValueChange = (newValue: OptionType) => {
    onChange(newValue)
  }

  const customStyles: StylesConfig<OptionType, IsMulti> = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      'font-weight': 'var(--bold)',
      border: className === 'selectInputProfessionHome' ? 'none' : '',
      textAlign: state.selectProps.textAlign,
      '@media screen and (min-width: 30rem)': {
        width: state.selectProps.width,
        lineHeight: className === 'selectInputHomeProfession' ? '2.40' : '',
      },

    }),

    input: (provided) => ({
      ...provided,
      'font-weight': 'var(--bold)',
      '& input': {
        font: 'inherit',
      },
    }),

    control: (provided, state) => ({
      ...provided,
      padding: '0 0.5625rem',
      borderColor: className === 'selectInputHomeProfession' ? 'transparent' : 'var(--gray-300)',
      outline: state.isFocused ? 'var(--outline)' : 'none',
      boxShadow: state.isFocused ? 'var(--outline-shadow)' : 'none',
      '-webkit-box-shadow': state.isFocused ? 'var(--outline-shadow)' : 'none',
      '&:hover': {
        borderColor: className === 'selectInputHomeProfession' ? 'transparent' : 'var(--gray-300)',
      },
    }),

    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? 'var(--primary-200)' : 'none',
      color: 'var(--gray)',
      'font-weight': 'var(--bold)',
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      display: className === 'selectInputHomeProfession' ? 'none' : '',
    }),

    placeholder: (provided) => ({
      ...provided,
      fontWeight: 700,
      fontSize: '.875rem',
      color: 'var(--gray-400)',
    }),

    singleValue: (provided, state: { isDisabled: boolean }) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      alignSelf: 'center',
      color: 'var(--gray-900)',
      fontWeight: 700,
    }),

    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'var(--primary)',
      borderRadius: '4px',
      color: 'var(--white)',
      lineHeight: '1.3rem',
      'font-weight': 'var(--bold-select-multi-item)',
    }),

    multiValueLabel: (provided) => ({
      ...provided,
      color: 'var(--white)',
      'font-weight': 'var(--bold-select-multi-item)',
    }),

    valueContainer: (provided) => ({
      ...provided,
      minHeight: className === 'selectInputHomeProfession' ? '1.5rem' : 'var(--input-height)',
      padding: className === 'selectInputHomeProfession' ? 'none' : '',
      fontSize: className === 'selectInputHomeProfession' ? 'var(--text-sm)' : '',
      'font-weight': 'var(--bold)',
    }),
  }

  return (
    <div
      className={cx(styles[`select-input--${className}`], {
        [styles['select-input--margin']]: margin,
      })}
    >
      <label
        htmlFor={name}
        className={cx(styles[`label--${className}`], {
          'sr-only ': hideLabel,
          [styles['label--gray']]: labelGray,
          [styles['label--dark']]: labelDark,
        })}
      >
        {label}
      </label>
      <Select
        inputId={name}
        options={options}
        isSearchable={search}
        styles={customStyles}
        className={className}
        name={name}
        width={width}
        textAlign={textAlign}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onInputChange={onInputChange}
        onChange={value => onValueChange(value as OptionType)}
        isMulti={multiple}
        components={separator
          ? {}
          : { IndicatorSeparator: () => null }
        }
        noOptionsMessage={() => noOptionsMessage}
      />
    </div>)
}
