import React, { FC } from 'react'
import cx from 'classnames'
import { Card } from '../../../card/card'

import styles from './offre-card.module.css'

interface OffreCardProps {
  title: string,
  text: string,
  marginTop?: boolean,
}

export const OffreCard: FC<OffreCardProps> = ({ title, text, marginTop }) => {
  return (
    <div className={cx(styles['offre-card'], marginTop ? styles['offre-card--marginTop'] : '')}>
      <Card backgroundColor="gray" className={styles['min-card']}>
        <div className={styles['offre-card__container']}>
          <h4 className={styles['offre-card__title']}>{title}</h4>
          <p className={styles['offre-card__text']}>{text}</p>
        </div>
      </Card>
    </div>
  )
}
