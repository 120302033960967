import { useEffect, useState } from 'react'
import { SousFamilleLabelEnum, useIsSousFamilleLazyQuery } from '../graphql/graphql'

export function useIsSousFamille() {
  const [ queryIsSousFamille, { data }] = useIsSousFamilleLazyQuery()
  const [ isSousFamille, setIsSousFamille ] = useState<boolean>(false)

  useEffect(() => {
    setIsSousFamille(data?.isSousFamille || false)
  }, [setIsSousFamille, data])

  const checkSousFamille = (sousFamille: SousFamilleLabelEnum, idProfession: string): void => {
    queryIsSousFamille({
      variables: {
        sousFamilleLabel: sousFamille,
        professionId: idProfession,
      },
    })
  }

  return { checkSousFamille, isSousFamille }
}
