import React, { FC, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { SelectInput } from '../../../components'
import { useGetCityLazyQuery } from '../../../graphql/graphql'
import { OptionType } from '../../../components/form/input/select-input/select-input'

export interface SelectCityProps {
  name: string,
  value?: OptionType,
  onChange: (value: OptionType[] | OptionType | undefined) => void,
}

export const SelectCity: FC<SelectCityProps> = ({ name, value, onChange }) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<OptionType[]>([])
  const [getCity, { data }] = useGetCityLazyQuery()

  const getCityDebounce = debounce(getCity, 150)

  useEffect(() => {
    if (data?.getCity) {
      const newCities: OptionType[] = []
      data.getCity.forEach(city => {
        newCities.push({
          label: `${city.name}, ${city.postcode}`,
          value: city.name,
        })

        if (city.name.includes('-')) {
          newCities.push({
            label: `${city.name.replace(/-/g, ' ')}, ${city.postcode}`,
            value: city.name.replace(/-/g, ' '),
          })
        }
      })
      setOptions(newCities)
    }
  }, [data])

  const onInputChange = (value: string) => {
    if (value.length >= 3) {
      getCityDebounce({ variables: { search: value } })
    }
  }

  return (
    <SelectInput
      label=""
      hideLabel={true}
      name={name}
      placeholder=""
      onInputChange={onInputChange}
      onChange={onChange}
      value={value}
      search={true}
      multiple={false}
      width="30rem"
      textAlign="left"
      options={options}
      noOptionsMessage={t('form.select.no_options')}
      className="selectCity"
    />
  )
}
