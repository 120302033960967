import { format, isFuture, isDate as isDateFns, formatDistance as formatDistanceFns } from 'date-fns'
import {
  fr,
  enGB,
} from 'date-fns/locale'

export type locale = 'fr' | 'enGB'
const locales = { fr, enGB }
const defaultFormat = 'dd/MM/yyyy'

export const formatter = (date: string | Date) => {
  return date && format((new Date(date)), 'yyyy/MM/dd')
}

export const formatDate = (date: Date, locale: locale) => {
  return date && format(date, defaultFormat, { locale: locales[locale] })
}

export const formatToString = (date: Date, formatString: string = defaultFormat, locale: locale) => {
  return format(date, formatString, { locale: locales[locale] })
}

export const formatterDateField = (date: string) => {
  return date && format((new Date(date)), 'yyyy-MM-dd')
}

export const getYear = (date: string) => {
  return date && format((new Date(date)), 'yyyy')
}

export const checkDateIsInFuture = (date: string) => {
  return date && isFuture(new Date(date))
}

export const isDate = (date: Date) => {
  return isDateFns(date)
}

export const formatDistance = (date: Date, locale: locale) => {
  return formatDistanceFns(date, new Date(), {
    locale: locales[locale],
    addSuffix: true,
  })
}
