import React, { FC, ReactNode } from 'react'

import cx from 'classnames'
import { Heading, TextSize } from '../h/heading'
import styles from './title.module.css'

interface SectionProps {
  children: ReactNode,
  align?: 'default' | 'center',
  level: 1 | 2 | 3 | 4 | 5 | 6,
  size?: TextSize,
  mobileSize?: TextSize,
  width?: 'default' | 'small'
}

export const Title: FC<SectionProps> = ({
  children,
  align,
  level,
  size,
  mobileSize,
  width,
}) => {
  return (
    <div className={cx(styles.title, styles[`title--${align}`], styles[`title--${width}`])}>
      <Heading level={level} size={size} mobileSize={mobileSize}>
        {children}
      </Heading>
    </div>
  )
}
