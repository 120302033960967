import { useEffect, useState } from 'react'

export function useStepper( initStep  = 1, nbSteps: number ) {
  const [step, setStep] = useState(initStep)
  const [lastStep, setLastStep] = useState(nbSteps)

  useEffect(() => {
    setLastStep(nbSteps)
  }, [nbSteps])

  const isLastStep = () =>{
    return step === lastStep
  }

  const nextStep = () => {
    return !isLastStep() ? setStep(step + 1) : step
  }

  const prevStep = () => {
    return step > 1 ? setStep(step - 1) : setStep(1)
  }

  const isCurrentState = (nbStep: number) => {
    return step == nbStep
  }

  const updateNbSteps = (totalSteps: number) => {
    setLastStep(totalSteps)
  }

  return { step, setStep, nextStep, prevStep, isLastStep, isCurrentState, updateNbSteps }
}
