import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import { getLocationDataProfession, IdentityForm, pushHistory } from '../register.helper'
import { paths } from '../../../routing'
import { Form } from './form'

export const Identity: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const professions = getLocationDataProfession(location)

  const onSubmit = (identity: IdentityForm) => {
    pushHistory(history, {
      current: paths.REGISTER_IDENTITY,
      next: paths.REGISTER_PROFESSION,
    }, {
      data: {
        identity,
        professions,
      },
    })
  }

  return (
    <Form onSubmit={onSubmit} />
  )
}
