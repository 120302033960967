import { FC, useEffect } from 'react'
import { useLocation } from 'react-router'
import { isBrowser } from '../../helpers/environnment'

export const ScrollTop: FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (isBrowser) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}
