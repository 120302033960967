import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { paths } from '../../../../routing'
import { HeaderItem } from '../../../header'
import { runtimeConfig } from '../../../../config'

export const HeaderItemsCommunication: FC = () => {
  const { t } = useTranslation()

  const menuItems = [
    {
      path: paths.QUI_SOMMES_NOUS,
      key: 'qui_sommes_nous.title',
      background: 'none',
    },
    {
      path: paths.EMPLOYEUR,
      key: 'employeur.title',
      background: 'none',
    },
    {
      path: `${runtimeConfig.TROOPS_URL}`,
      key: 'connect',
      background: 'none',
      isExternal: true,
    },
    {
      path: paths.REGISTER_IDENTITY,
      key: 'registered',
      background: 'tertiary',
    },
  ]

  return (
    <>
      {menuItems.map(item => {
        return (
          <HeaderItem
            href={item.path}
            key={item.key}
            isExternal={item.isExternal || false}
            background={item.background === 'tertiary' ? 'tertiary' :  'none' }
          >
            {t(item.key)}
          </HeaderItem>
        )
      })}
    </>
  )
}
