import { InitOptions } from 'i18next'
import { formatDistance, formatToString, isDate, locale } from '../../helpers/date.helper'
import { runtimeConfig } from '../../config'
import { translations } from './translations'

export const FR = 'fr'
export const languages = [FR]
export const nameSpace = 'translations'

export const baseOptions: Partial<InitOptions> = {
  resources: translations,
  debug: false,
  lng: FR,
  load: 'languageOnly',
  preload: languages,
  whitelist: languages,
  fallbackLng: 'fr',
  returnEmptyString: false,
  defaultNS: nameSpace,
  ns: nameSpace,
}

export const backend = {
  loadPath: `${runtimeConfig.LOCALES_ENDPOINT}/locales/{{lng}}/{{ns}}.json`,
  addPath: `${runtimeConfig.LOCALES_ENDPOINT}/locales/add/{{lng}}/{{ns}}`,
}

export const options: InitOptions = {
  ...baseOptions,
  backend,
  react: {
    bindI18n: 'languageChanged',
    wait: true,
    bindI18nStore: false,
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
    format: function (value, format, lng) {
      if (isDate(value)) {
        if (format === 'distance') {
          return formatDistance(value, lng as locale)
        }

        return formatToString(value, format, lng as locale)
      }
      return value
    },
  },
}
