import React, { FC } from 'react'

export const cguCandidatUrl = 'https://static.ipokrat.fr/RAMPAGE%20-%20CGU%20CANDIDATS%20VDEF%20020421.pdf'
export const cguEmployeurUrl = 'https://static.ipokrat.fr/RAMPAGE%20-%20CGU%20RECRUTEURS%20VDEF%20020421.pdf'
export const mentionsLegalesUrl = 'https://static.ipokrat.fr/RAMPAGE%20-%20MENTIONS%20LEGALES%20020421.pdf'

export interface CGUProps {
  label: string
  type?: 'candidat' | 'employeur',
  className?: string
}

export const CGU: FC<CGUProps> = ({ label, type = 'candidat', className= '' }) => (
  <a
    href={type === 'candidat'
      ? cguCandidatUrl
      : cguEmployeurUrl}
    download
    target="_blank"
    rel="noreferrer noopener"
    className={className}
  >
    {label}
  </a>)
