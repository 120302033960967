import React, { FC, ReactElement } from 'react'

import styles from './proposition-valeur-article.module.css'

interface PropositionValeurArticleProps {
  text: string,
  icon: ReactElement,
}

export const PropositionValeurArticle: FC<PropositionValeurArticleProps> = ({ text, icon }) => {
  return (
    <article className={styles['proposition-valeur-article']}>
      <div className={styles['proposition-valeur-article__container']}>
        <div className={styles['proposition-valeur-article__icon']}>
          {icon}
        </div>
        <p className={styles['proposition-valeur-article__text']}>
          {text}
        </p>
      </div>
    </article>
  )
}
