import React, { FC } from 'react'
import cx from 'classnames'
import { Caption } from './caption'

import './image.css'

export interface ImageProps {
  src: string,
  srcSet?: string,
  alt: string,
  caption?: string,
  position?: number,
}

export const Image: FC<ImageProps> = ({ src, srcSet, alt, caption, position }) => {
  return (
    <figure
      className={cx('article')}
      style={{
        zIndex: position,
      }}
    >
      <img
        src={src}
        srcSet={srcSet}
        alt={alt}
        className='article__image'
      />
      <Caption caption={caption} />
    </figure>
  )
}
