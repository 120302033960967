import React, { FC } from 'react'
import cx from 'classnames'

import './benefices-recherches-article.css'
import { isEmpty } from 'lodash'
import grayArrow from '../../../../assets/images/icon/assets-icon-outline-arrow-small-right-gray-600.svg'

interface beneficesRecherchesArticleProps {
  title: string,
  text?: string,
  reverse?: boolean
  img: {
    src: string,
    alt: string,
    srcset: string[],
  },
  link?: {
    href: string,
    text: string,
  },
}

export const BeneficesRecherchesArticle: FC<beneficesRecherchesArticleProps> = ({
  title,
  text,
  reverse = false,
  img,
  link = {},
}) => {
  return (
    <article
      className={cx('benefices-recherches-article', {
        ['benefices-recherches-article--reverse']: reverse,
      })}
    >
      <div className="benefices-recherches-article__img">
        <img
          className="benefices-recherches-article__img-tag"
          alt={img.alt}
          src={img.src}
          srcSet={img.srcset.join(',')}
        />
      </div>
      <div className="benefices-recherches-article__text">
        <h4>
          {title}
        </h4>
        {text && (
          <p>
            {text}
            {!isEmpty(link) && (
              <>
                <a href={link.href}>{link.text}
                  <img src={grayArrow} alt="arrow" />
                </a>
              </>
            )}
          </p>
        )}
      </div>
    </article>
  )
}
