import React, { FC } from 'react'
import { Block } from '../block/block'

export const Chiffres:FC= ({ children }) => {
  return (
    <Block column color="gray50">
      {children}
    </Block>
  )
}
