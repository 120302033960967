import React, { FC } from 'react'
import { Block } from '../block'
import { EngagementButtons } from './engagement-buttons'
import './engagement.css'

export interface EngagementProps {
  title: string,
  text: string,
  buttonFindJob: string,
  buttonRecuitmentOffer: string,
}

export const Engagement: FC<EngagementProps> = ({ title, text, buttonFindJob, buttonRecuitmentOffer }) => {
  return (
    <Block title={title} size="small" titleAlign="center" titleSize="4xl" titleMobileSize="3xl" titleWidth="small">
      <article className="engagement">
        <p className="engagement__text">{text}</p>
        <EngagementButtons firstButton={buttonFindJob} secondButton={buttonRecuitmentOffer} />
      </article>
    </Block>
  )
}
