import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import styles from './footer-link-block.module.css'

interface Links {
  href: string,
  label: string,
  download?: boolean,
}

export interface FooterLinkBlockProps {
  title: string,
  links: Links[],
}

export const FooterLinkBlock: FC<FooterLinkBlockProps> = ({ title, links, children }) => {
  return (
    <div className={styles['footer-link-block']}>
      <p className={styles['footer-link-block__title']}>{title}</p>
      <ul className={styles['footer-link-block__link-list']}>
        {links.map(({ href, label, download }) => (
          <li
            key={href}
            className={styles['footer-link-block__link-li']}
          >
            {!download
              ? (
                <Link
                  to={href}
                  className={styles['footer-link-block__link']}
                >
                  {label}
                </Link>)
              : (
                <a
                  href={href}
                  download
                  target="_blank"
                  rel="noreferrer noopener"
                  className={styles['footer-link-block__link']}
                >
                  {label}
                </a>)}
          </li>
        ))}
        {children}
      </ul>
    </div>
  )
}
