import React, { FC, ReactNode } from 'react'
import { Avatar } from '../../../avatar'

import './chat-item.css'

export type positionType = 'left' | 'right'

export interface ChatItemProps {
    children: ReactNode,
    position?: positionType,
    avatar?: boolean
}

export const ChatItem: FC<ChatItemProps> = ({
  children,
  position = 'left',
  avatar = false,
}) => {
  return (
    <div className={`chat-item__container chat-item__container--${position}`}>
      {avatar && (
        <Avatar />
      )}
      <ul className="chat-item__element">
        {children}
      </ul>
    </div>
  )
}
