import React, { FC, ReactNode } from 'react'
import { Block } from '../block'

interface BeneficeRecherchesProps {
  title: string
  children?: ReactNode,
}

export const BeneficeRecherches: FC<BeneficeRecherchesProps> = ({ title, children }) => {
  return (
    <Block
      title={title}
      column={true}
      color="gray50"
      sectionPadding="large"
      titleAlign="center"
      titleWidth="small"
      titleSize="5xl"
      titleMobileSize="4xl"
    >
      {children}
    </Block>
  )
}
