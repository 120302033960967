import React, { FC, ReactNode } from 'react'
import { Block } from '../block'
import { Button } from '../../form'
import { paths } from '../../../routing'

import styles from './typologie-profession.module.css'

export interface TypologieProfessionProps {
  title: string,
  children?: ReactNode,
  buttonText?: string,
}

export const TypologieProfession: FC<TypologieProfessionProps> = ({ title, children, buttonText }) => {
  return (
    <Block
      title={title}
      titleSize="4xl"
      titleMobileSize="3xl"
      column={true}
      className={styles['typologie-profession']}
      sectionPadding="none"
    >
      {children}
      <div className={styles['profession__button-container']}>
        {buttonText && (
          <Button
            link={paths.REGISTER}
            size="medium"
            padding="home"
            backgroundColor="tertiary"
          >
            {buttonText}
          </Button>)}
      </div>
    </Block>
  )
}
