import React, { FC } from 'react'
import cx from 'classnames'

import styles from './icon.module.css'

export interface PublicIconProps {
  size?: 'small' | 'medium',
  bg?: 'gray' | 'dark-gray',
}

interface IconProps extends PublicIconProps {
  src: string,
}

export const Icon: FC<IconProps> = ({ src, size = 'small', bg }) => {
  return (
    <div className={cx(styles.icon, styles[`icon--${bg}`])}>
      <img
        className={cx(styles.icon__image, styles[`icon__image--${size}`], styles[`icon__image--${bg}`])}
        src={src}
        alt=""
        aria-disabled
      />
    </div>)
}
