import React, { FC } from 'react'
import cx from 'classnames'

import styles from './horizontal-rule.module.css'

type sizeType = 'bold' | 'normal' | 'thin'

interface HorizontalRuleProps {
  size?: sizeType,
  className?: string,
}

export const HorizontalRule: FC<HorizontalRuleProps> = ({ size= 'normal', className = '' }) => {
  return (
    <hr className={cx(styles['horizontal-rule'], styles[`horizontal-rule--${size}`], className)} />
  )
}
