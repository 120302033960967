import ReactGA from 'react-ga4'
import { runtimeConfig } from '../config'

export const GA_ID = `${runtimeConfig.GA_MEASUREMENT_ID}`

export const USER_CATEGORY = 'User'
export const USER_ACTION = 'Created an Account'

export const init = () => {
  // active le debug mode en dev et staging
  const isDev = process.env.RAZZLE_STAGE === 'staging' || process.env.RAZZLE_ENV === 'development'

  ReactGA.initialize(GA_ID,  {
    testMode: isDev,
  })
}

export const sendEvent = (payload: { action: string, category: string }) => {
  ReactGA.event(payload)
}

export const sendPageview = (path: string) => {
  ReactGA.send({ hitType: 'pageview', page: path })
}
