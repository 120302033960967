import React, { FC, createContext } from 'react'

export const FeatureContext = createContext<unknown>({})

export interface FeatureProviderProps {
  features: unknown,
}

export const FeatureProvider: FC<FeatureProviderProps> = ({ features, children }) => {
  return (
    <FeatureContext.Provider value={features}>
      {children}
    </FeatureContext.Provider>
  )
}
