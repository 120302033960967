import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import { PosteTypeEnum } from '../../../graphql/graphql'
import { Checkbox } from '../../../components/form/checkbox/checkbox'
import { ProfessionForm } from '../../../pages/register/register.helper'

type PosteType =
  'CHEF_DE_CLINIQUE' |
  'CHEF_DE_SERVICE' |
  'INTERNE' |
  'LIBERAL' |
  'PRATICIEN_HOSPITALIER' |
  'PEU_IMPORTE'

interface CheckboxPosteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: any,
  professionInputs?: ProfessionForm
}


export const CheckboxPoste: FC<CheckboxPosteProps> = ({ inputRef, professionInputs }) => {
  const { t } = useTranslation()

  const options = [
    { value: PosteTypeEnum.CHEF_DE_CLINIQUE, label: t('register.job_criteria.poste.chef_clinique') },
    { value: PosteTypeEnum.CHEF_DE_SERVICE, label: t('register.job_criteria.poste.chef_service') },
    { value: PosteTypeEnum.INTERNE, label: t('register.job_criteria.poste.interne') },
    { value: PosteTypeEnum.LIBERAL, label: t('register.job_criteria.poste.liberal') },
    { value: PosteTypeEnum.PRATICIEN_HOSPITALIER, label: t('register.job_criteria.poste.praticien_hospitalier') },
    { value: PosteTypeEnum.PEU_IMPORTE, label: t('register.job_criteria.poste.peu_importe') },
  ]

  return (
    <>
      { options.map((option) => (
        <Checkbox
          key={option.value}
          name={option.value}
          label={option.label}
          inputRef={inputRef}
          defaultChecked={professionInputs ? professionInputs[`${option.value}` as PosteType] : false}
          boldLabel
          testId={`checkbox-${option.value}`}
        />
      ))}
    </>
  )
}
