import React, { FC, MouseEvent } from 'react'
import { renderRoutes, RouteConfig } from 'react-router-config'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import { Button, FormLayout } from '../../components'
import { paths } from '../../routing'
import credentialIllustration from '../../assets/images/illustrations/assets-media-illustrations-grey-connexion.jpg'

interface RegisterProps {
  route?: RouteConfig,
}

export const Register: FC<RegisterProps> = ({ route }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  if (pathname === paths.REGISTER) {
    return <Redirect from={paths.REGISTER} to={paths.REGISTER_IDENTITY} />
  }

  const cancel = (e: MouseEvent) => {
    e.preventDefault()
    history.replace(paths.REGISTER_IDENTITY, null)
    history.push(paths.HOME)
  }

  const onPrev = (e: MouseEvent) => {
    e.preventDefault()
    history.goBack()
  }

  return (
    <FormLayout
      onCrossClick={cancel}
      bottomBar={true}
      doublePanel={pathname === paths.REGISTER_CREDENTIALS}
      illustration={pathname === paths.REGISTER_CREDENTIALS && <img src={credentialIllustration} alt="register" />}
      bottomChildren={(
        <Button onClick={onPrev}>
          {t('prev')}
        </Button>
      )}
    >
      {renderRoutes(route?.routes)}
    </FormLayout>
  )
}
