import React from 'react'
import {
  HeaderCommunication,
  HeaderItemsCommunication,
  LayoutCommunication,
  NotFoundBlock,
} from '../../components'

export function NotFound() {

  return (
    <LayoutCommunication>
      <HeaderCommunication noHero>
        <HeaderItemsCommunication />
      </HeaderCommunication>
      <NotFoundBlock />
    </LayoutCommunication>
  )
}
