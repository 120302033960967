import React, { FC } from 'react'
import { Button } from '../../../form'
import { paths } from '../../../../routing'
import styles from './engagement-button.module.css'

interface EngagementButtonsProps {
  firstButton: string,
  secondButton: string,
}

export const EngagementButtons: FC<EngagementButtonsProps> = ({ firstButton, secondButton }) => {
  return (
    <div className={styles['engagement__button-container']}>
      <Button
        link={paths.REGISTER}
        backgroundColor="tertiary"
        size="small"
        padding="home"
      >
        {firstButton}
      </Button>
      <Button
        link={paths.EMPLOYEUR}
        backgroundColor="default"
        size="small"
        padding="home"
        color="gray"
      >
        {secondButton}
      </Button>
    </div>
  )
}
