import React, { FC } from 'react'
import cx from 'classnames'

import styles from './vertical-rule.module.css'

type sizeType = 'bold' | 'normal' | 'thin'

interface VerticalRuleProps {
  size?: sizeType,
  className?: string,
}

export const VerticalRule: FC<VerticalRuleProps> = ({ size= 'normal', className = '' }) => {
  return (
    <hr className={cx(styles['vertical-rule'], styles[`vertical-rule--${size}`], className)} />
  )
}
