import React, { FC, ReactNode } from 'react'
import cx from 'classnames'

import styles from './section.module.css'

interface SectionProps {
  children: ReactNode,
  size?: 'small' | 'default',
  padding?: 'none' | 'normal' | 'large' | 'top-bottom',
}

export const Section: FC<SectionProps> = ({ padding = 'normal', size = 'default', children }) => {
  return (
    <section
      className={
        cx(
          styles.section,
          styles[`section--padding-${padding}`],
          styles[`section--${size}`],
        )}
    >
      {children}
    </section>
  )
}
