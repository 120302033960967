import React, { FC } from 'react'
import { Footer } from '../../footer'

import styles from './layout.module.css'

export const LayoutCommunication: FC = ({ children }) => {
  return (
    <section className={styles['layout-communication']}>
      {children}
      <Footer />
    </section>
  )
}

