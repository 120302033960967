import React, { FC } from 'react'
import cx from 'classnames'
import { Button } from '../../form'
import { HeaderCommunication, HeaderItemsCommunication } from '../header'

import styles from './hero.module.css'


export interface HeroProps {
  title: string,
  type: 'home' | 'fondateur' | 'employeur',
  subtitle?: string,
  titleFull?: boolean,
  button?: string,
  buttonLink?: string,
  justifyContent?: 'start' | 'end' | 'space-between',
}

export const Hero: FC<HeroProps> = ({
  title,
  type,
  subtitle,
  titleFull,
  button,
  buttonLink,
  justifyContent = 'start',
}) => {

  return (
    <section
      className={cx(
        styles.hero,
        styles[`hero--${type}`],
        styles[`hero--${justifyContent}`],
      )}
    >
      <HeaderCommunication>
        <HeaderItemsCommunication />
      </HeaderCommunication>
      <div className={styles.hero__text}>
        <div
          className={cx(styles['hero__text-container'], {
            [styles['hero__text-container--full']]: titleFull,
          })}
        >
          <h1
            className={cx(
              styles['hero__text-title'],
              {
                [styles['hero__text-title--employer']]: type === 'employeur',
              })}
          >{title}</h1>
          {subtitle && (
            <h2
              className={cx(styles['hero__text-subtitle'],{
                [styles['hero__text-subtitle--employer']] : type === 'employeur',
              })}
            >
              {subtitle}
            </h2>)}
          {button && buttonLink && (
            <Button externLink={buttonLink} padding="home">
              {button}
            </Button>
          )}
        </div>
      </div>
    </section>
  )
}

export default Hero
