import React, { FC, ReactNode } from 'react'
import { Block } from '../block'
import { Button } from '../../form'

import styles from './offre.module.css'

interface OffreProps {
  title: string,
  children: ReactNode,
  buttonText: string,
  buttonLink: string,
}

export const Offre: FC<OffreProps> = ({
  title,
  children,
  buttonText,
  buttonLink,
}) => {
  return (
    <Block title={title} titleSize="5xl" titleMobileSize="4xl" titleAlign="center" color="gray50" column={true}>
      <div className={styles['offre']}>
        {children}
      </div>
      <div className={styles.offre__buttons}>
        <Button externLink={buttonLink} padding="home">
          {buttonText}
        </Button>
      </div>
    </Block>
  )
}
