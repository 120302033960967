import React, { FC, ReactNode, MouseEvent } from 'react'
import cx from 'classnames'
import { AppBar } from '../../appbar'
import { IpokratLogo } from '../../logo'
import { Cross } from '../../icon'
import { Button } from '../button'

import styles from './form-layout.module.css'

export interface FormLayoutProps {
  children: ReactNode,
  bottomChildren?: ReactNode,
  onCrossClick?: (e: MouseEvent) => void,
  illustration?: ReactNode,
  bottomBar?: boolean,
  doublePanel?: boolean,
}

export const FormLayout: FC<FormLayoutProps> = ({
  onCrossClick,
  children,
  bottomChildren,
  bottomBar = false,
  illustration,
  doublePanel = false,
}) => {
  return (
    <div
      className={cx(styles['form-layout'], {
        [styles['form-layout--single']]: !doublePanel,
        [styles['form-layout--both']]: doublePanel,
        [styles['from-layout--botom-bar']]: bottomBar,
      })}
    >
      <AppBar position="top" justifyContent="space-between" shadow={true}>
        <IpokratLogo color="colored" className={styles['form-layout__logo']} />
        <Button
          backgroundColor="none"
          onClick={(e) => onCrossClick && onCrossClick(e)}
          testId="cancel-form-layout"
        >
          <Cross />
        </Button>
      </AppBar>
      <div
        className={cx(styles['form-layout__panel-container'], {
          [styles['form-layout__panel-container--both']]: doublePanel,
        })}
      >
        <div
          className={cx({
            [styles['form-layout__left-panel--single']]: !doublePanel,
            [styles['form-layout__left-panel--both']]: doublePanel,
          })}
        >
          <div
            className={cx({
              [styles['form-layout__container--single']]: !doublePanel,
              [styles['form-layout__container--both']]: doublePanel,
            })}
          >
            <div
              className={cx(styles['form-layout__container-body'], {
                [styles['form-layout__container-body--single']]: !doublePanel,
                [styles['form-layout__container-body--both']]: doublePanel,
              })}
            >
              {children}
            </div>
          </div>
        </div>
        {doublePanel && (
          <div className={styles['form-layout__right-panel']}>
            {illustration}
          </div>
        )}
      </div>
      { bottomBar && (
        <AppBar position="bottom" justifyContent="space-between">
          {bottomChildren}
        </AppBar>
      )}
    </div>
  )
}
