import React, { FC, InputHTMLAttributes, ReactNode } from 'react'
import cx from 'classnames'

import './checkbox.css'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string,
  dataTest?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: any,
  label: string | ReactNode,
  required?: boolean
  error?: string,
  boldLabel?: boolean,
  thinLabel?: boolean,
  testId?: string,
}

export const Checkbox: FC<CheckboxProps> = ({
  name,
  dataTest,
  label,
  boldLabel = false,
  thinLabel = false,
  inputRef,
  error,
  testId,
  ...props
}) => {
  return (
    <div className="checkbox" data-test={dataTest}>
      <label
        htmlFor={name}
        id={`label-${name}`}
      >
        <input
          type="checkbox"
          id={name}
          name={name}
          ref={inputRef}
          className="checkbox__input"
          {...props}
        />
        <span
          className={cx('checkbox__custom', {
            'checkbox__custom--error': error,
          })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="checkbox__custom-svg"
          >
            <path
              fill="#fff"
              d="M18.707 7.293c.188.188.293.442.293.707 0 .265-.105.52-.293.707l-8 8c-.188.188-.442.293-.707.293-.265 0-.52-.105-.707-.293l-4-4c-.182-.189-.283-.441-.28-.703.002-.263.107-.513.292-.699.186-.185.436-.29.699-.293.262-.002.514.099.703.281L10 14.586l7.293-7.293C17.481 7.106 17.735 7 18 7c.265 0 .52.106.707.293z"
              transform="translate(-297 -602) translate(160) translate(135 120) translate(0 321) translate(0 159) translate(2 2)"
            />
          </svg>
        </span>
        <span
          className={cx('checkbox__label', {
            'checkbox--error': error,
            'checkbox__label--bold': boldLabel,
            'checkbox__label--thin': thinLabel,
          })}
          data-test={testId}
        >
          {label}
        </span>
      </label>
      {error && (<p className="input__error-message">{error}</p>)}
    </div>
  )
}
