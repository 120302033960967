import React, { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { head, get } from 'lodash'
import { ChatItem, ChatItemBubble, ChatLayout, SelectInput } from '../../../components'
import {
  getLocationDataProfession,
  isOptionType,
  isOptionTypeArray,
  ProfessionForm,
  ProfessionsForm,
} from '../../../pages/register/register.helper'
import { SousFamilleLabelEnum, useGetSpecialitesQuery } from '../../../graphql/graphql'
import { OptionType } from '../../../components/form/input/select-input/select-input'
import {
  SelectProfession,
  SelectSpeciality,
  CheckboxPoste,
  SelectService,
  CheckboxDomaine,
  CheckboxEtablissement,
} from '../../input'
import { useStepper } from '../../../hooks'
import { useIsSousFamille } from '../../../hooks/use-is-sous-famille'
import { initStepper, updateStepper } from '../../../pages/register/profession/profession.helper'

export interface ProfessionFormProps {
  onSubmit: (data: ProfessionsForm) => void
}

export const ProfessionChat: FC<ProfessionFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const location = useLocation()
  // Hook qui vérifie si le métier choisi est de la sous-famille spécifiée (ici, sous-famille de type 'soin')
  const { checkSousFamille, isSousFamille: isSoin } = useIsSousFamille()
  // Query qui fetch toutes les spécialités qui existent
  const { data: specialiteQuery } = useGetSpecialitesQuery()
  // profession contient toutes les datas de l'history de ce step
  const profession = getLocationDataProfession(location)
  // On récupère les inputs de l'objet précédent
  const professionInputs = head(profession?.professionInputs)
  // Historique de l'input wishPositionId (choix de profession)
  const wishPositionId = get(professionInputs, 'wishPositionId')
  // Historique de l'input hasSpecialite (l'utilisateur a-t-il une spécialité ou non)
  const hasSpecialite = get(professionInputs, 'hasSpecialite')
  // Historique de l'input serviceTags (les services spécifiés par l'utilisateurs)
  const serviceTags = get(professionInputs, 'serviceTags')
  // Dans professionInputs, isSoin est un booleen qui est setté depuis le formulaire de la home.
  // Il permet de savoir si le metier rentré à ce moment la est de la sous-famille 'soin'. (Cf formulaire de la home)
  const isSoinFromHomeForm = get(professionInputs, 'isSoin')

  const { control, watch, handleSubmit, register } = useForm<ProfessionForm>()
  // le watchall est trigger à chaque changement d'un input dans le form. À remplacer par des onChange ?
  const watchAll = watch()
  // Ce useState permet de setter dynamiquement un booleen à true ou false en fonction de si l'input hasSpecialite est
  // coché ou non par l'utilisateur. Cela va trigger ou non l'apparition du champ suivant (Choix de la spécialité)
  // On utilise un booleen car ces deux inputs font partie du même step, ils ne sont donc pas contrôlable par le useStep
  const [hasSpecialiteOption, setHasSpecialiteOption] = useState<OptionType>()
  // Permet de stocker la valeur de choosenProfession est de la setter automatiquement depuis l'historique si besoin.
  const [choosenProfession, setChoosenProfession] = useState<OptionType>()

  // Cette fonction calcule si le métier choisi possèdes spécialités, en comparant le métier choisi avec la liste
  // des spécialités reccueillie plutôt dans une query. Puis elle retourne la liste des spécialités en question.
  const computeSpecialites = useCallback((choosenProfessionId: string | undefined) => {
    const professionSpecialites = specialiteQuery?.getSpecialites?.filter(({ professionId }) => {
      return `${professionId}` === choosenProfessionId
    })

    return professionSpecialites?.map(({ label, externalId }) => (
      {
        value: String(externalId),
        label: t(`professions.${label}`),
      }),
    )
  }, [t, specialiteQuery])

  // Le useStepper dont le initStep dépend des valeurs des champs dans l'historique (on ne commence pas au step 0 si
  // des données sont déja présentes dans l'historique). Le nombre de steps totaux change également est changeant aussi.
  const { step, setStep, nextStep, isLastStep, updateNbSteps } = useStepper(
    initStepper(serviceTags, hasSpecialite, wishPositionId),
    updateStepper(
      isSoin || isSoinFromHomeForm,
      serviceTags,
      wishPositionId,
      choosenProfession,
    ))

  useEffect(() => {
    // Si on change la profession, on clear les champs, on setStep(0) et on update le nb de step restants en fonction
    // de la nouvelle profession qui a été choisie
    if (choosenProfession !== watchAll.wishPositionId) {
      setChoosenProfession(watchAll.wishPositionId)
      // So profession inputs are not re-attributed to cleared fields
      profession ? profession.professionInputs = undefined : ''
      setStep(0)
      updateNbSteps(updateStepper(
        (isSoin || isSoinFromHomeForm),
        serviceTags,
        wishPositionId,
        choosenProfession,
      ))
    }
  }, [
    watchAll,
    profession,
    setStep,
    choosenProfession,
    computeSpecialites,
    updateNbSteps,
    isSoin,
    isSoinFromHomeForm,
    serviceTags,
    wishPositionId,
  ])

  // Permet de setter le boolean hasSpecialite et le la valeur de choosenProfession en fonction de ce qui est déjà
  // présent dans l'historique
  useEffect(() => {
    setHasSpecialiteOption(hasSpecialite)
    setChoosenProfession(wishPositionId)
  }, [setStep, hasSpecialite, wishPositionId])

  // on change sur le choix de profession. Update le nb de steps dynamiquement au changement de cet input
  const handlerChangeWishPosition = (wishPosition: OptionType) => {
    // on verifie egalement a chaque changement si le metier rentré est de type soin ou non
    checkSousFamille(SousFamilleLabelEnum.SOINS, wishPosition.value)
  }

  const handlerHasSpecialiteOption = (hasSpecialite: OptionType) => {
    setHasSpecialiteOption(hasSpecialite)
  }

  // submit du form si on est au lastStep
  const onFormSubmit = (data: ProfessionForm) => {
    if (isLastStep()) {
      const professions = {
        wishPositionIds: data.specialites
          ? data.specialites
          : [data.wishPositionId],
        professionInputs: [{
          ...data,
        }],
      }

      return onSubmit(professions)
    }

    nextStep()
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <ChatLayout>
        <ChatItem avatar>
          <ChatItemBubble fade={step === 0 ? 'ask' : 'none'}>
            {t('what_job_do_you_seek')}
          </ChatItemBubble>
        </ChatItem>
        <ChatItem position="right">
          <ChatItemBubble fade={step === 0 ? 'answer' : 'none'}>
            <Controller
              name="wishPositionId"
              control={control}
              rules={{
                required: true,
                validate: value => isOptionType(value),
              }}
              defaultValue={wishPositionId || ''}
              render={props =>
                (
                  <SelectProfession
                    name={props.name}
                    onChange={(e) => {
                      props.onChange(e)
                      handlerChangeWishPosition(e as OptionType)
                    }}
                    value={props.value}
                  />
                )}
            />
          </ChatItemBubble>
        </ChatItem>
        {step >= 1 && (isSoin || isSoinFromHomeForm || serviceTags) && (
          <>
            <ChatItem avatar>
              <ChatItemBubble fade={step === 1 ? 'ask' : 'none'}>
                {t('register.job_criteria.service_tag.label')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={step === 1 ? 'answer' : 'none'}>
                <Controller
                  name="serviceTags"
                  control={control}
                  rules={{
                    required: false,
                    validate: value => isOptionTypeArray(value),
                  }}
                  defaultValue={serviceTags || ''}
                  render={props =>
                    (
                      <SelectService
                        name={props.name}
                        onChange={props.onChange}
                        value={props.value}
                      />
                    )}
                />
              </ChatItemBubble>
            </ChatItem>
          </>
        )}
        {step >= 1 && (!isSoin && !isSoinFromHomeForm && !serviceTags) && (
          <>
            <ChatItem avatar>
              <ChatItemBubble fade={step === 1 ? 'ask' : 'none'}>
                {t('confirm_profession', { profession: choosenProfession?.label })}
              </ChatItemBubble>
              <ChatItemBubble fade={step === 1 ? 'ask' : 'none'}>
                {t('ask_speciality')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={step === 1 ? 'answer' : 'none'}>
                <Controller
                  name="hasSpecialite"
                  control={control}
                  rules={{
                    required: true,
                    validate: value => isOptionType(value),
                  }}
                  defaultValue={hasSpecialite || ''}
                  render={props => {
                    return (
                      <SelectInput
                        label="hasSpecialite"
                        name="hasSpecialite"
                        hideLabel={true}
                        className="selectInputHasSpecialite"
                        search={false}
                        onChange={(e) => {
                          props.onChange(e)
                          handlerHasSpecialiteOption(e as OptionType)
                        }}
                        value={props.value}
                        options={
                          [
                            {
                              value: '1',
                              label: t('i_am_specialized'),
                            },
                            {
                              value: '0',
                              label: t('i_am_not_specialized'),
                            },
                          ]
                        }
                        placeholder={t('are_you_specialized')}
                        width="30rem"
                        textAlign="left"
                      />
                    )
                  }}
                />
              </ChatItemBubble>
            </ChatItem>
            {hasSpecialiteOption?.value === '1' && (
              <ChatItem position="right">
                <ChatItemBubble fade={(step === 1 && hasSpecialiteOption?.value === '1') ? 'answer' : 'none'}>
                  <Controller
                    name="specialites"
                    control={control}
                    rules={{
                      required: true,
                      validate: value => isOptionTypeArray(value),
                    }}
                    defaultValue={head(profession?.professionInputs)?.specialites || ''}
                    render={props => {
                      return (
                        <SelectSpeciality
                          name={props.name}
                          profession={choosenProfession?.value}
                          onChange={props.onChange}
                          value={props.value}
                        />
                      )
                    }}
                  />
                </ChatItemBubble>
              </ChatItem>
            )}
          </>
        )}
        {step >= 2 && (
          choosenProfession?.label === t('professions.medecin') ||
          wishPositionId?.label === t('professions.medecin')) && (
          <>
            <ChatItem avatar>
              <ChatItemBubble fade={step === 2 ? 'ask' : 'none'}>
                {t('register.job_criteria.poste.question')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={step === 2 ? 'ask' : 'none'} textPadding>
                <p>
                  {t('register.job_criteria.poste.label')}
                </p>
                <CheckboxPoste
                  professionInputs={professionInputs}
                  inputRef={register}
                />
              </ChatItemBubble>
            </ChatItem>
          </>
        )}
        {step >= 2 && (isSoin || isSoinFromHomeForm || serviceTags) && (
          <>
            <ChatItem avatar>
              <ChatItemBubble fade={step === 2 ? 'ask' : 'none'}>
                {t('register.job_criteria.domaine.question')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={step === 2 ? 'ask' : 'none'} textPadding>
                <p>
                  {t('register.job_criteria.domaine.label')}
                </p>
                <CheckboxDomaine
                  professionInputs={professionInputs}
                  inputRef={register}
                />
              </ChatItemBubble>
            </ChatItem>
          </>
        )}
        {step >= 3 &&
        (
          choosenProfession?.label === t('professions.medecin') ||
          wishPositionId?.label === t('professions.medecin')) && (
          <>
            <ChatItem avatar>
              <ChatItemBubble fade={step === 3 ? 'ask' : 'none'}>
                {t('register.job_criteria.etablissement.question')}
              </ChatItemBubble>
            </ChatItem>
            <ChatItem position="right">
              <ChatItemBubble fade={step === 3 ? 'ask' : 'none'} textPadding>
                <p>
                  {t('register.job_criteria.etablissement.label')}
                </p>
                <CheckboxEtablissement
                  inputRef={register}
                  professionInputs={professionInputs}
                />
              </ChatItemBubble>
            </ChatItem>
          </>
        )}
      </ChatLayout>
    </form>
  )
}
