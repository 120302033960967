import React, { FC } from 'react'
import styles from './histoire-article.module.css'

interface HistoireArticleProps {
  text: string,
}

export const HistoireArticle: FC<HistoireArticleProps> = ({ text }) => {
  return (
    <article className={styles['histoire-article']}>
      <div className={styles['histoire-article__container']}>
        <p className={styles['histoire-article__text']}>
          {text}
        </p>
      </div>
    </article>
  )
}
