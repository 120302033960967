import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectInput } from '../../../components'
import { OptionType } from '../../../components/form/input/select-input/select-input'
import { useGetProfessionsQuery } from '../../../graphql/graphql'

export interface SelectProfessionProps {
  name: string,
  value?: OptionType,
  onChange: (value: OptionType[] | OptionType | undefined) => void,
}

export const SelectProfession: FC<SelectProfessionProps> = ({ name, value, onChange }) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<OptionType[]>([])
  const { data, loading } = useGetProfessionsQuery()

  useEffect(() => {
    if (data?.getProfessions) {
      setOptions(data.getProfessions.map(profession => ({
        value: String(profession.externalId),
        label: t(`professions.${profession.label}`),
      })))
    }
  }, [data, t])

  return loading
    ? null
    : (
      <SelectInput
        label="profession"
        name={name}
        hideLabel={true}
        onChange={onChange}
        className="selectInputProfession"
        value={value}
        options={options}
        placeholder={t('seek_job')}
        width="30rem"
        textAlign="left"
      />
    )
}
