import React, { FC } from 'react'
import cx from 'classnames'
import './chat-item-bubble.css'

export type fadeType = 'ask' | 'answer' | 'none'

export interface ChatItemBubbleProps {
    fade?: fadeType
    textPadding?: boolean
}

export const ChatItemBubble: FC<ChatItemBubbleProps> = ({
  children,
  fade,
  textPadding = false,
}) => {
  return (
    <li
      className={cx({
        'chat-item-bubble': true,
        'fade-in--none': fade === 'none',
        'fade-in--ask': fade === 'ask',
        'fade-in--answer': fade ==='answer',
        'text-padding': textPadding,
      })}
    >
      {children}
    </li>
  )
}
