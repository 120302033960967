import { isNil } from 'lodash'
import { OptionType } from '../../../components/form/input/select-input/select-input'

const professionWithSpecialities = [
  'Dentiste',
  'Pharmacien',
]

export const initStepper = (
  serviceTags?: OptionType[],
  hasSpecialite?: OptionType,
  wishPositionId?: OptionType,
): number => {
  if (!isNil(hasSpecialite) && wishPositionId?.label === 'Médecin') {
    return 3
  }
  if (!isNil(serviceTags)) {
    return 2
  }
  if (!isNil(hasSpecialite)) {
    return 1
  }

  return 0
}

export const updateStepper = (
  isSoin?: boolean,
  serviceTags?: OptionType[],
  wishPositionId?: OptionType,
  choosenProfession?: OptionType,
): number => {
  const isMedecin = wishPositionId?.label === 'Médecin' || choosenProfession?.label === 'Médecin'
  const isProfessionWithSpecialities =
    professionWithSpecialities.includes(wishPositionId?.label as string)
    || professionWithSpecialities.includes(choosenProfession?.label as string)

  if (isMedecin) {
    return 3
  } else if (isSoin || serviceTags) {
    return 2
  } else if (isProfessionWithSpecialities) {
    return 1
  } else {
    return 0
  }
}
