import React, { FC } from 'react'
import avatar from './../../assets/images/illustrations/avatar-chat@2x.jpg'

import styles from './avatar.module.css'

export interface AvatarProps {
  urlPic?: string,
}

export const Avatar: FC<AvatarProps> = ({ urlPic = avatar }) => {
  return (
    <div className={styles.avatar}>
      <img src={urlPic} className={styles.avatar__img} alt="avatar" />
    </div>
  )
}
