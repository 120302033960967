import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  LayoutCommunication,
  ProccessStep,
  VerticalStepper,
  StepperItem,
  HeaderCommunication,
  AvantagesBlock,
  HorizontalRule,
  CardAvantages,
  AvantagesItem,
} from '../../components'

import recompense from '../../assets/images/illustrations/assets-recompense.jpg'
import recompense2x from '../../assets/images/illustrations/assets-recompense@2x.jpg'
import { HeaderItemsCommunication } from '../../components/communication/header/headerItems/header-Items'
import { paths } from '../../routing'


export const VosAvantages: FC = () => {
  const { t } = useTranslation()

  const steps = [
    {
      step: 1,
      text: t('vos_avantages.process_step.step1'),
    },
    {
      step: 2,
      text: t('vos_avantages.process_step.step2'),
    },
    {
      step: 3,
      text: t('vos_avantages.process_step.step3'),
    },
  ]

  const avantages = [
    {
      title: t('vos_avantages.avantages.item1.title'),
      text: t('vos_avantages.avantages.item1.text'),
      item1: {
        label: t('vos_avantages.avantages.item1.first.label'),
        avantage: t('vos_avantages.avantages.item1.first.avantage'),
      },
      item2: {
        label: t('vos_avantages.avantages.item1.second.label'),
        avantage: t('vos_avantages.avantages.item1.second.avantage'),
      },
      item3: {
        label: t('vos_avantages.avantages.item1.third.label'),
        last: {
          first: t('vos_avantages.avantages.item1.third.last.first'),
          end: t('vos_avantages.avantages.item1.third.last.end'),
        },
      },
    },
    {
      title: t('vos_avantages.avantages.item2.title'),
      text: t('vos_avantages.avantages.item2.text'),
      item1: {
        label: t('vos_avantages.avantages.item2.first.label'),
        avantage: t('vos_avantages.avantages.item2.first.avantage'),
      },
      item2: {
        label: t('vos_avantages.avantages.item2.second.label'),
        avantage: t('vos_avantages.avantages.item2.second.avantage'),
      },
      item3: {
        label: t('vos_avantages.avantages.item2.third.label'),
        last: {
          first: t('vos_avantages.avantages.item2.third.last.first'),
          end: t('vos_avantages.avantages.item2.third.last.end'),
        },
      },
    },
    {
      title: t('vos_avantages.avantages.item3.title'),
      text: t('vos_avantages.avantages.item3.text'),
      item1: {
        label: t('vos_avantages.avantages.item3.first.label'),
        avantage: t('vos_avantages.avantages.item3.first.avantage'),
      },
      item2: {
        label: t('vos_avantages.avantages.item3.second.label'),
        avantage: t('vos_avantages.avantages.item3.second.avantage'),
      },
      item3: {
        label: t('vos_avantages.avantages.item3.third.label'),
        last: {
          first: t('vos_avantages.avantages.item3.third.last.first'),
          end: t('vos_avantages.avantages.item3.third.last.end'),
        },
      },
    },
  ]

  return (
    <LayoutCommunication>
      <HeaderCommunication noHero>
        <HeaderItemsCommunication />
      </HeaderCommunication>
      <ProccessStep
        noPadding
        title={(t('vos_avantages.process_step.title'))}
        stepsChildren={(
          <VerticalStepper>
            {steps.map( step => <StepperItem key={step.step} step={step.step} text={step.text} />)}
          </VerticalStepper>
        )}
        img={{
          alt: '',
          src: recompense,
          srcset: [
            `${recompense2x} 2x`,
          ],
        }}
      />
      <AvantagesBlock
        link={{
          text: t('vos_avantages.avantages.link'),
          href: paths.REGISTER,
        }}
        subLabels={[
          t('vos_avantages.avantages.sub_label1'),
          t('vos_avantages.avantages.sub_label2'),
          t('vos_avantages.avantages.sub_label3'),
        ]}
      >
        {
          avantages.map(avantage => {
            return (
              <CardAvantages
                key={avantage.title}
                title={avantage.title}
                text={avantage.text}
              >
                <AvantagesItem label={avantage.item1.label} avantage={avantage.item1.avantage} />
                <HorizontalRule size="thin" />
                <AvantagesItem label={avantage.item2.label} avantage={avantage.item2.avantage} />
                <HorizontalRule size="thin" />
                <AvantagesItem
                  label={avantage.item3.label}
                  last={{ first: avantage.item3.last.first, end: avantage.item3.last.end }}
                />
              </CardAvantages>
            )
          })
        }
      </AvantagesBlock>
    </LayoutCommunication>
  )
}
