import { useEffect, useState } from 'react'
import { isSSR } from '../helpers/environnment'

export function usePageOffsetBrowser() {
  const [pageXOffset, setPageXOffset] = useState<number>()
  const [pageYOffset, setPageYOffset] = useState<number>()

  const listener = () => {
    setPageXOffset(window?.pageXOffset || 0)
    setPageYOffset(window?.pageYOffset || 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  })

  return {
    pageXOffset,
    pageYOffset,
  }
}

export function usePageOffsetServer() {
  return {
    pageXOffset: 0,
    pageYOffset: 0,
  }
}

export const usePageOffset = isSSR
  ? usePageOffsetServer
  : usePageOffsetBrowser
