import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionType, SelectInput } from '../../../components/form/input/select-input/select-input'
import { useGetProfessionsQuery } from '../../../graphql/graphql'

export interface SelectProfessionHomeProps {
  name: string,
  value?: OptionType,
  onChange: (value: OptionType[] | OptionType | undefined) => void,
}

export const SelectProfessionHome: FC<SelectProfessionHomeProps> = ({
  name,
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<OptionType[]>([])
  const { data, loading } = useGetProfessionsQuery()

  useEffect(() => {
    if (data?.getProfessions) {
      setOptions(data.getProfessions.map(profession => ({
        value: String(profession.externalId),
        label: t(`professions.${profession.label}`),
      })))
    }
  }, [data, t])

  return loading
    ? null
    : (
      <SelectInput
        label={t('profession_home')}
        name={name}
        hideLabel={false}
        onChange={onChange}
        className="selectInputHomeProfession"
        value={value}
        options={options}
        placeholder={t('seek_job_home')}
        textAlign="left"
      />
    )
}
